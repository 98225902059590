import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { TooltipPosition } from '@shared/enum/tooltip.enums';

@Component({
    selector: 'app-popup-cancel-booking',
    templateUrl: './popup-cancel-booking.component.html',
    styleUrls: ['./popup-cancel-booking.component.scss'],
})
export class PopupCancelBookingComponent {
    @Input() type?: string;
    @Input() flightDetail: any;
    @Input() itinerary: any;
    @Input() selectedPassenger: any;
    @Input() isCallAPI?: boolean;
    @Output() backToStatusNote = new EventEmitter<any>();
    @Output() confirmCancel = new EventEmitter<any>();

    TooltipPosition: typeof TooltipPosition = TooltipPosition;
    cancelForm: FormGroup;
    collapsedStates: boolean[] = [];
    isShowPopupDiscard: boolean = false;
    isShowPopupConfirm: boolean = false;
    sectionVisibility: any = [];
    currentFareRule: string;
    currentIndexFareRule: number;
    itineraries: any;
    priceDetails: any;
    baseImage = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
    fareRuleVi: string;
    resource: string;
    currentSegments: any;
    numAdults: number = 0;
    numChildren: number = 0;
    numInfants: number = 0;
    serviceData = [
        {
            numSeat: 0,
            totalSeat: 0,
            numMeal: 0,
            totalMeal: 0,
            numExtraBaggage: 0,
            totalExtraBaggage: 0,
        },
        {
            numSeat: 0,
            totalSeat: 0,
            numMeal: 0,
            totalMeal: 0,
            numExtraBaggage: 0,
            totalExtraBaggage: 0,
        },
    ];
    totalPrice: number = 0;

    constructor(
        private fb: FormBuilder,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.cancelForm = this.fb.group({
            isRefund: [null, [Validators.required]],
        });
        // Initialize collapsed states
        this.flightDetail?.flight_info?.itineraries.forEach((itinerary: any, index: number) => {
            this.sectionVisibility[index] = {
                state: false,
                tickets: false,
                taxes: false,
                addon: false,
                priceTickets: false,
                priceAddon: false
            };
        });
        this.itineraries = this.flightDetail?.flight_info?.itineraries;
        this.priceDetails = this.flightDetail?.price_details;

        if (!this.itinerary || this.itinerary?.isChosenDepart) {
            this.currentIndexFareRule = 0;
            this.currentSegments = this.itineraries[0]?.segments;
            this.currentFareRule = this.itineraries[0]?.fare_rule;
            this.fareRuleVi = this.itineraries[0]?.fare_rule_vi;
            this.resource = this.itineraries[0]?.resource;
            this.totalPrice += this.priceDetails?.price_details?.[0]?.final_price;
        } else {
            this.currentIndexFareRule = 1;
            this.currentSegments = this.itineraries[1]?.segments;
            this.currentFareRule = this.itineraries[1]?.fare_rule;
            this.fareRuleVi = this.itineraries[1]?.fare_rule_vi;
            this.resource = this.itineraries[1]?.resource;
        }

        if (this.itinerary?.isChosenReturn) {
            this.totalPrice += this.priceDetails?.price_details?.[1]?.final_price;
        }

        this.formatAddOns(this.flightDetail?.passenger_information);
    }

    get f(): { [key: string]: AbstractControl } {
        return this.cancelForm.controls;
    }

    formatAddOns(passengers: any) {
        passengers.forEach((passenger: any) => {
            // Count num passengers
            if (passenger.passenger_type === "Adult") this.numAdults++;
            if (passenger.passenger_type === "Child") this.numInfants++;
            if (passenger.passenger_type === "Infant") this.numChildren++;

            // SEAT
            if (passenger?.departure_seat?.length) {
                this.serviceData[0].numSeat += passenger.departure_seat.length;
                passenger.departure_seat.forEach((seat: any) => {
                    this.serviceData[0].totalSeat += seat.price;
                });
            }
            if (passenger?.return_seat?.length) {
                this.serviceData[1].numSeat += passenger.return_seat.length;
                passenger.return_seat.forEach((seat: any) => {
                    this.serviceData[1].totalSeat += seat.price;
                });
            }
            // EXTRA_BAGGAGE
            if (passenger?.departure_extra_baggage?.length) {
                this.serviceData[0].numExtraBaggage +=
                    passenger.departure_extra_baggage.length;
                passenger.departure_extra_baggage.forEach((baggage: any) => {
                    this.serviceData[0].totalExtraBaggage += baggage.price;
                });
            }
            if (passenger?.return_extra_baggage?.length) {
                this.serviceData[1].numExtraBaggage +=
                    passenger.return_extra_baggage.length;
                passenger.return_extra_baggage.forEach((baggage: any) => {
                    this.serviceData[1].totalExtraBaggage += baggage.price;
                });
            }
            // MEAL
            if (passenger?.departure_meals?.length) {
                passenger.departure_meals.forEach((segment: any) => {
                    if (segment.meals?.length) {
                        segment.meals.forEach((meal: any) => {
                            this.serviceData[0].numMeal += meal.quantity;
                            this.serviceData[0].totalMeal += meal.price;
                        });
                    }
                });
            }
            if (passenger?.return_meals?.length) {
                passenger.return_meals.forEach((segment: any) => {
                    if (segment.meals?.length) {
                        segment.meals.forEach((meal: any) => {
                            this.serviceData[1].numMeal += meal.quantity;
                            this.serviceData[1].totalMeal += meal.price;
                        });
                    }
                });
            }
        });
    }

    toggleSection(section: string, index: number) {
        this.sectionVisibility[index][section] = !this.sectionVisibility[index][section];
    }

    isSectionVisible(section: string, index: number): boolean {
        return this.sectionVisibility[index][section];
    }

    toggleCollapse(index: number) {
        this.collapsedStates[index] = !this.collapsedStates[index];
    }

    formatTime(timeString: string): string {
        const timeParts = timeString.split(':');
        const date = new Date();
        date.setHours(Number(timeParts[0]));
        date.setMinutes(Number(timeParts[1]));

        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
    }

    formatDuration(timeString: string, space: boolean): string {
        const timeParts = timeString.split(':');
        const hours = Number(timeParts[0]);
        const minutes = Number(timeParts[1]);

        let formattedDuration = '';

        if (hours > 0) {
            if (space) {
                formattedDuration += `${hours}h `;
            } else {
                formattedDuration += `${hours}h`;
            }
        }

        formattedDuration += `${minutes}m`;

        return formattedDuration;
    }

    formatBaggages(baggage: any): string {
        if (baggage?.number && baggage?.weight) {
            return `${baggage?.number}x${baggage?.weight} ${baggage?.unit}`;
        } else if (baggage?.number && baggage?.weight == null) {
            return `${baggage?.number} ${this.translateService.instant('COMMON.PCS')}`;
        } else if (baggage?.number == null && baggage?.weight) {
            return `${baggage?.weight} ${baggage?.unit}`;
        } else if (baggage?.description == 'included') {
            return this.translateService.instant('FLIGHT.VIEW_DETAILS_SIDEBAR.INCLUDED');
        } else
            return '';
    }

    toggleFareRule(index: number) {
        this.currentIndexFareRule = index;

        const itinerary = this.flightDetail.flight_info?.itineraries[index];
        if (itinerary) {
            this.currentFareRule = itinerary.fare_rule;
            this.fareRuleVi = itinerary.fare_rule_vi;
            this.resource = itinerary.resource
        }
    }

    onChangeYesOrNo(value: boolean) {
        if (value) {
            this.cancelForm.addControl('refundAmount', this.fb.control(null, [Validators.required]));
            this.cancelForm.addControl('deductAmount', this.fb.control(null, [Validators.required]));
        } else {
            this.cancelForm.removeControl('refundAmount');
            this.cancelForm.removeControl('deductAmount');
        }
        this.cancelForm.updateValueAndValidity();
    }

    onClickCancel() {
        this.isShowPopupDiscard = true;
    }

    onClickConfirm() {
        if (this.cancelForm.valid) {
            this.isShowPopupConfirm = true;
        } else {
            this.cancelForm.markAllAsTouched();
            setTimeout(() => {
                this.scrollToFirstInvalidControl();
            }, 0);
        }
    }

    private scrollToFirstInvalidControl() {
        let form = document.getElementById('cancelForm');
        let firstInvalidControl = form!.getElementsByClassName('is-invalid')[0];
        if (firstInvalidControl) {
            firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
            (firstInvalidControl as HTMLElement).focus();
        }
    }

    onKeepEditing() {
        this.isShowPopupDiscard = false;
        this.isShowPopupConfirm = false;
    }

    onDiscard() {
        // event.stopPropagation();
        this.isShowPopupDiscard = false;
        this.backToStatusNote.emit();
    }

    onForceConfirm() {
        this.isShowPopupConfirm = false;
        this.confirmCancel.emit(this.cancelForm.value);
    }

    replaceWithDefaultImage(event: any): void {
        event.target.src = 'assets/images/user-management/default-image.jpg';
    }
}
