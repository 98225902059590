<div class="relative {{id}}">
    <div class="relative">
        <i *ngIf="icon" [ngClass]="icon" class="absolute left-4 top-3 z-20"></i>
        <input
            #input
            type="search"
            class="suggest-search {{ class }} {{ icon ? 'has-icon' : ''}}"
            [id]="id"
            [formControl]="control"
            [placeholder]="placeholder | translate"
            autocomplete="off"
            [ngClass]="ngClass"
            (input)="onInputChange($event)"
            (focus)="onFocus()"
            (blur)="onBlur()"
        />
    </div>
    <!-- Suggest Search Items -->
    <ng-container *ngIf="isShowSuggestions">
        <div class="absolute rounded-lg bg-white z-[99] custom-shadow min-w-full max-w-[460px] max-h-[280px] overflow-auto"
            [ngClass]="{
                'top-auto bottom-[calc(100%+4px)]': dropdownPosition === 'above',
                'top-[calc(100%+4px)] bottom-auto': dropdownPosition === 'below',
                'left-auto right-0': dropdownAlign === 'right',
                'left-0 right-auto': dropdownAlign === 'left'
            }">
            <ng-content></ng-content>
        </div>
    </ng-container>
</div>