import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';

@Component({
    selector: 'app-popup-flight-itinerary',
    templateUrl: './popup-flight-itinerary.component.html',
    styleUrls: ['./popup-flight-itinerary.component.scss'],
})
export class PopupFlightItineraryComponent {
    @Input() depart: any;
    @Input() return: any;
    @Output() clickCancel = new EventEmitter<any>();
    @Output() clickContinue = new EventEmitter<any>();

    isValidateItinerary: boolean = false;
    isChosenDepart: boolean = false;
    isChosenReturn: boolean = false;
    numStopDateDepart: number = 0;
    numStopDateReturn: number = 0;
    baseImage = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

    ngOnInit() {
        // Calculate date stop depart and arrival
        this.numStopDateDepart = this.calculateDateStop(
            this.depart?.segments?.[0]?.date_departure_at,
            this.depart?.segments?.[this.depart?.segments?.length - 1]?.date_arrival_at
        );

        this.numStopDateReturn = this.calculateDateStop(
            this.return?.segments?.[0]?.date_departure_at,
            this.return?.segments?.[this.return?.segments?.length - 1]?.date_arrival_at
        );
    }

    onClickItinerary(type: string) {
        if (type === 'DEPART') {
            this.isChosenDepart = !this.isChosenDepart
        } else {
            this.isChosenReturn = !this.isChosenReturn
        }
    }

    calculateDateStop(startDate?: string, endDate?: string): number {
        if (!startDate || !endDate) return 0;
        const start = new Date(startDate).getTime();
        const end = new Date(endDate).getTime();
        
        return Math.max(0, (end - start) / (1000 * 3600 * 24));
    }

    replaceWithDefaultImage(event: any): void {
        event.target.src = 'assets/images/user-management/default-image.jpg';
    }

    onClickCancel() {
        this.clickCancel.emit();
    }

    onClickContinue() {
        if (!this.isChosenDepart && !this.isChosenReturn) {
            this.isValidateItinerary = true;
        } else {
            this.clickContinue.emit({
                isChosenDepart: this.isChosenDepart,
                isChosenReturn: this.isChosenReturn,
            });
        }
    }
}
