export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
};
export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];

export const DEFAULT_SEARCH_PARAMS = {
  page_num: 1,
  page_size: 20,
}

export const SOCTRIP_HOTLINE = '1900571248';

export const URL_COUNTRY_CODE = 'https://ipgeolocation.abstractapi.com/v1/?api_key=';
export const APIKEY_COUNTRY_CODE = 'da81649210074b6f8003c7903841046f';

export const TIME_OUT_REQUEST = 30000

export const FILTER_OPTIONS: any = [
  {
    id: 1,
    title_filter: 'Accompanying service',
    params: 'accompanying_service_list',
    is_show_filter: true,
    data_filter: [
      {
        name: 'Free breakfast',
        value: 'FREE_BREAKFAST',
        number_of_hotel: null,
      },
      {
        name: 'Discount promotion',
        value: 'DISCOUNT_PROMOTION',
        number_of_hotel: null,
      },
      {
        name: 'Free cancellation',
        value: 'FREE_CANCELLATION',
        number_of_hotel: null,
      },
      {
        name: 'No prepayment required',
        value: 'NO_PREPAYMENT_REQUIRED',
        number_of_hotel: null
      },
    ],
  },
  {
    id: 2,
    title_filter: 'Property type',
    params: 'property_type_list',
    is_show_filter: true,
    data_filter: [
      {
        id: 1,
        name: 'Homestays',
        value: 'HOMESTAY',
        number_of_hotel: 106,
      },
      {
        id: 2,
        name: 'Entire homes',
        value: 'ENTIRE_HOME',
        number_of_hotel: 1066,
      },
      {
        id: 3,
        name: 'Apartments',
        value: 'APARTMENT',
        number_of_hotel: 1098,
      },
      {
        id: 4,
        name: 'Hotels',
        value: 'HOTEL',
        number_of_hotel: 4525,
      },
      {
        id: 5,
        name: 'Resorts',
        value: 'RESORT',
        number_of_hotel: 5665,
      },
      {
        id: 6,
        name: 'Motels',
        value: 'MOTEL',
        number_of_hotel: 5665,
      },
      {
        id: 7,
        name: 'Villas',
        value: 'VILLA',
        number_of_hotel: 5665,
      },
    ],
  },
  {
    id: 3,
    title_filter: 'Distance from centre',
    params: 'distance_from_center_list',
    is_show_filter: true,
    data_filter: [
      {
        name: '<2 km from center',
        value: 'LESS_THAN_2',
        number_of_hotel: null,
      },
      {
        name: '2-5 km from center',
        value: 'TWO_TO_FIVE',
        number_of_hotel: null,
      },
      {
        name: '>5 km from center',
        value: 'GREATER_THAN_5',
        number_of_hotel: null,
      },
    ],
  },
  {
    id: 4,
    title_filter: 'Convenient',
    params: 'convenient_list',
    is_show_filter: true,
    data_filter: [
      {
        name: 'Private bathroom',
        value: 'PRIVATE_BATHROOM',
        number_of_hotel: 5665,
      },
      {
        name: 'Balcony',
        value: 'BALCONY',
        number_of_hotel: 2424,
      },
      {
        name: 'Air conditioner',
        value: 'AIR_CONDITIONING',
        number_of_hotel: 1373,
      },
      {
        name: 'View sea',
        value: 'VIEW_SEA',
        number_of_hotel: 1523,
      },
      {
        name: 'Parking',
        value: 'PARKING',
        number_of_hotel: 1803,
      },
      {
        name: 'Spa',
        value: 'SPA',
        number_of_hotel: 2710,
      },
    ],
  },
  {
    id: 5,
    title_filter: 'Reviews from customers',
    params: 'review_score_list',
    is_show_filter: true,
    data_filter: [
      {
        name: '9+ Superb',
        value: '9',
        number_of_hotel: 15,
      },
      {
        name: '8+ Excellent',
        value: '8',
        number_of_hotel: 125,
      },
      {
        name: '7+ Very good',
        value: '7',
        number_of_hotel: 85,
      },
      {
        name: '6+ Pleasant',
        value: '6',
        number_of_hotel: 485,
      },
    ],
  },
];

export const MEDIA_FILE_SIZE_LIMITS_BYTES = {
  image: 5242880,
  video: 104857600
};

export const MEDIA_FILE_FORMAT_LIMITS = {
  image: [
    'image/avif',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/jpg',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'image/tiff'
  ],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/3gpp',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/quicktime'
  ]
}

export const MEDIA_TYPE = {
  image: 'Image',
  video: 'Video',
  file: 'File'
};

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const CHARACTER_TRANSLATION_LIMIT = 5000;
export const MAX_TRUNCATED_TEXT_HEIGHT = 300;
export const MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = 135;
export const FARE_CLASS = [
  { key: "Economy", en: "Economy", vi: "Phổ thông" },
  { key: "Premium Economy", en: "Premium Economy", vi: "Phổ thông đặc biệt" },
  { key: "Business", en: "Business", vi: "Thương gia" },
  { key: "First", en: "First", vi: "Hạng nhất" },
  { key: "VN_ECONOMY_SUPER_LITE_P", en: "Economy Super Lite P", vi: "Phổ thông siêu tiết kiệm P" },
  { key: "VN_ECONOMY_SUPER_LITE_A", en: "Economy Super Lite A", vi: "Phổ thông siêu tiết kiệm A" },
  { key: "VN_ECONOMY_SUPER_LITE_G", en: "Economy Super Lite G", vi: "Phổ thông siêu tiết kiệm G" },
  { key: "VN_ECONOMY_LITE_Q", en: "Economy Lite Q", vi: "Phổ thông tiết kiệm Q" },
  { key: "VN_ECONOMY_LITE_N", en: "Economy Lite N", vi: "Phổ thông tiết kiệm N" },
  { key: "VN_ECONOMY_LITE_R", en: "Economy Lite R", vi: "Phổ thông tiết kiệm R" },
  { key: "VN_ECONOMY_LITE_T", en: "Economy Lite T", vi: "Phổ thông tiết kiệm T" },
  { key: "VN_ECONOMY_LITE_E", en: "Economy Lite E", vi: "Phổ thông tiết kiệm E" },
  { key: "VN_ECONOMY_CLASSIC_S", en: "Economy Classic S", vi: "Phổ thông tiêu chuẩn S" },
  { key: "VN_ECONOMY_CLASSIC_H", en: "Economy Classic H", vi: "Phổ thông tiêu chuẩn H" },
  { key: "VN_ECONOMY_CLASSIC_K", en: "Economy Classic K", vi: "Phổ thông tiêu chuẩn K" },
  { key: "VN_ECONOMY_CLASSIC_L", en: "Economy Classic L", vi: "Phổ thông tiêu chuẩn L" },
  { key: "VN_ECONOMY_FLEX_B", en: "Economy Flex B", vi: "Phổ thông linh hoạt B" },
  { key: "VN_ECONOMY_FLEX_M", en: "Economy Flex M", vi: "Phổ thông linh hoạt M" },
  { key: "VN_PREMIUM_ECONOMY_CLASSIC_Z", en: "Premium Economy Classic Z", vi: "Phổ thông đặc biệt tiêu chuẩn Z" },
  { key: "VN_PREMIUM_ECONOMY_CLASSIC_U", en: "Premium Economy Classic U", vi: "Phổ thông đặc biệt tiêu chuẩn U" },
  { key: "VN_PREMIUM_ECONOMY_FLEX_W", en: "Premium Economy Flex W", vi: "Phổ thông đặc biệt linh hoạt W" },
  { key: "VN_BUSINESS_CLASSIC_D", en: "Business Classic D", vi: "Thương gia tiêu chuẩn D" },
  { key: "VN_BUSINESS_CLASSIC_I", en: "Business Classic I", vi: "Thương gia tiêu chuẩn I" },
  { key: "VN_BUSINESS_FLEX_J", en: "Business Flex J", vi: "Thương gia linh hoạt J" },
  { key: "VN_BUSINESS_FLEX_C", en: "Business Flex C", vi: "Thương gia linh hoạt C" },
  { key: "QH_ECONOMY_SAVER_MAX_A", en: "Economy Saver Max A", vi: "Phổ thông tiết kiệm tối đa A" },
  { key: "QH_ECONOMY_SAVER_MAX_V", en: "Economy Saver Max V", vi: "Phổ thông tiết kiệm tối đa V" },
  { key: "QH_ECONOMY_SAVER_MAX_U", en: "Economy Saver Max U", vi: "Phổ thông tiết kiệm tối đa U" },
  { key: "QH_ECONOMY_SAVER_MAX_R", en: "Economy Saver Max R", vi: "Phổ thông tiết kiệm tối đa R" },
  { key: "QH_ECONOMY_SAVER_MAX_O", en: "Economy Saver Max O", vi: "Phổ thông tiết kiệm tối đa O" },
  { key: "QH_HOT_DEAL_G", en: "Hot Deal G", vi: "Ưu đãi đặc biệt G" },
  { key: "QH_ECONOMY_SMART_X", en: "Economy Smart X", vi: "Phổ thông thông minh X" },
  { key: "QH_ECONOMY_SMART_T", en: "Economy Smart T", vi: "Phổ thông thông minh T" },
  { key: "QH_ECONOMY_SMART_Q", en: "Economy Smart Q", vi: "Phổ thông thông minh Q" },
  { key: "QH_ECONOMY_SMART_N", en: "Economy Smart N", vi: "Phổ thông thông minh N" },
  { key: "QH_ECONOMY_SMART_M", en: "Economy Smart M", vi: "Phổ thông thông minh M" },
  { key: "QH_ECONOMY_SMART_L", en: "Economy Smart L", vi: "Phổ thông thông minh L" },
  { key: "QH_ECONOMY_SMART_K", en: "Economy Smart K", vi: "Phổ thông thông minh K" },
  { key: "QH_ECONOMY_SMART_H", en: "Economy Smart H", vi: "Phổ thông thông minh H" },
  { key: "QH_ECONOMY_FLEX_B", en: "Economy Flex B", vi: "Phổ thông linh hoạt B" },
  { key: "QH_ECONOMY_FLEX_W", en: "Economy Flex W", vi: "Phổ thông linh hoạt W" },
  { key: "QH_ECONOMY_FLEX_Y", en: "Economy Flex Y", vi: "Phổ thông linh hoạt Y" },
  { key: "QH_BUSINESS_SMART_D", en: "Business Smart D", vi: "Thương gia thông minh D" },
  { key: "QH_BUSINESS_SMART_I", en: "Business Smart I", vi: "Thương gia thông minh I" },
  { key: "QH_BUSINESS_SMART_C", en: "Business Smart C", vi: "Thương gia thông minh C" },
  { key: "QH_BUSINESS_FLEX_J", en: "Business Flex J", vi: "Thương gia linh hoạt J" },
  { key: "QH_BUSINESS_FLEX_F", en: "Business Flex F", vi: "Thương gia linh hoạt F" },
  { key: "VU_ECO_SAVER_A", en: "Economy Saver A", vi: "Phổ thông tiết kiệm A" },
  { key: "VU_ECO_SAVER_B", en: "Economy Saver B", vi: "Phổ thông tiết kiệm B" },
  { key: "VU_ECO_SAVER_C", en: "Economy Saver C", vi: "Phổ thông tiết kiệm C" },
  { key: "VU_ECO_SAVER_D", en: "Economy Saver D", vi: "Phổ thông tiết kiệm D" },
  { key: "VU_ECO_SAVER_E", en: "Economy Saver E", vi: "Phổ thông tiết kiệm E" },
  { key: "VU_ECO_SAVER_H", en: "Economy Saver H", vi: "Phổ thông tiết kiệm H" },
  { key: "VU_ECO_SAVER_K", en: "Economy Saver K", vi: "Phổ thông tiết kiệm K" },
  { key: "VU_ECO_SAVER_L", en: "Economy Saver L", vi: "Phổ thông tiết kiệm L" },
  { key: "VU_ECO_SAVER_M", en: "Economy Saver M", vi: "Phổ thông tiết kiệm M" },
  { key: "VU_ECO_SAVER_P", en: "Economy Saver P", vi: "Phổ thông tiết kiệm P" },
  { key: "VU_ECO_FLEX_N", en: "Economy Flex N", vi: "Phổ thông linh hoạt N" },
  { key: "VU_ECO_FLEX_O", en: "Economy Flex O", vi: "Phổ thông linh hoạt O" },
  { key: "VU_ECO_FLEX_Q", en: "Economy Flex Q", vi: "Phổ thông linh hoạt Q" },
  { key: "VU_ECO_FLEX_R", en: "Economy Flex R", vi: "Phổ thông linh hoạt R" },
  { key: "VU_ECO_FLEX_S", en: "Economy Flex S", vi: "Phổ thông linh hoạt S" },
  { key: "VU_ECO_FLEX_T", en: "Economy Flex T", vi: "Phổ thông linh hoạt T" },
  { key: "VU_ECO_FLEX_V", en: "Economy Flex V", vi: "Phổ thông linh hoạt V" },
  { key: "VU_ECO_FLEX_Y", en: "Economy Flex Y", vi: "Phổ thông linh hoạt Y" },
  { key: "VU_PRE_ECO_J", en: "Premium Economy J", vi: "Phổ thông đặc biệt J" },
  { key: "VJ_ECO", en: "Economy", vi: "Phổ thông" },
  { key: "VJ_DELUXE", en: "Deluxe", vi: "Phổ thông cao cấp" },
  { key: "VJ_SKYBOSS", en: "SkyBoss", vi: "SkyBoss" },
  { key: "VJ_BUSINESS", en: "Business", vi: "Thương gia" }
]
