import { NgModule } from '@angular/core';
import { CountdownFormatPipe } from './pipes/coundown-format.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { SortByInteractionsPipe } from './pipes/sort-by-interactions.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { BytesConversionPipe } from './pipes/bytes-conversion.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';

import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { ToastComponent } from './components/toast/toast.component';
import { Toast, ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DropdownModule } from 'primeng/dropdown';
import { GalleriaModule } from 'primeng/galleria';
import { OverlayModule } from 'primeng/overlay';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'primeng/chart';
import { SkeletonModule } from 'primeng/skeleton';

import { ShowImageModalComponent } from './components/show-image-modal/show-image-modal.component';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { CustomizePaginationComponent } from './components/customize-pagination/customize-pagination.component';
import { PriceFormatComponent } from './components/price-format/price-format.component';
import { CommonIconText } from './components/common-icon-text/common-icon-text.component';
import { TooltipModuleBooking } from './components/tooltip/tooltip.module';
import { StarComponent } from '@shared/components/star/star.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LoadingComponent } from './components/loading/loading.component';
import { InputPhoneNumberComponent } from './components/input-phone-number/input-phone-number.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { CalendarDashboardComponent } from './components/calendar-dashboard/calendar-dashboard.component';
import { CalendarModule } from 'primeng/calendar';
import { UnderTestComponent } from './components/under-test/under-test.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { SentenceCasePipe } from './pipes/sentence-case.pipe';
import { ButtonComponent } from './components/button/button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { DatetimeFormatterModule, DateFormatPipe } from '@soctrip-common/datetime-formatter'
import { DatePipe } from '@angular/common';
import { PaginationByUrlComponent } from './components/pagination-by-url/pagination-by-url.component';
import { BannerReservationFlightComponent } from './components/banner-reservation-flight/banner-reservation-flight.component';
import { FlightScheduleComponent } from './components/flight-schedule/flight-schedule.component';
import { FareRuleComponent } from './components/fare-rule/fare-rule.component';
import { BookingInfoComponent } from './components/booking-info/booking-info.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { SeatSelectionComponent } from './components/seat-selection/seat-selection.component';
import { ExtraBaggageComponent } from './components/extra-baggage/extra-baggage.component';
import { MealSelectionComponent } from './components/meal-selection/meal-selection.component';
import { SuggestSearchComponent } from './components/suggest-search/suggest-search.component';
import { PassengersInfoComponent } from './components/passengers-info/passengers-info.component';
import { PopupFlightItineraryComponent } from './components/popup-flight-itinerary/popup-flight-itinerary.component';
import { PopupCancelBookingComponent } from './components/popup-cancel-booking/popup-cancel-booking.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PreviewMediaComponent } from './components/preview-media/preview-media.component';
import { BaggageDetailsComponent } from './components/flight-schedule/baggage-details/baggage-details.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { CurrencyConversionPipe, HistoryCurrencyPipe } from '@soctrip-common/app-security';
import { CarouselComponent } from './components/carousel/carousel.component';
import { InputMaskModule } from 'primeng/inputmask';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { PriceFormatUsdComponent } from './components/price-format-usd/price-format-usd.component';

const sharedPipes = [
  CountdownFormatPipe,
  TruncateStringPipe,
  RelativeTimePipe,
  CustomDatePipe,
  RelativeTimePipe,
  NumberFormatPipe,
  SortByInteractionsPipe,
  SortPipe,
  SlugifyPipe,
  BytesConversionPipe,
  FilterPipe,
  SentenceCasePipe,
  SafeHtmlPipe,
  CapitalizePipe,
];
const shareProviders = [Toast, DialogService, DynamicDialogRef, DatePipe, DateFormatPipe];
const shareModules = [
  DialogModule,
  ButtonModule,
  InputTextModule,
  FormsModule,
  ReactiveFormsModule,
  OverlayPanelModule,
  BsDatepickerModule,
  ModalModule,
  DropdownModule,
  ToastModule,
  TagModule,
  TooltipModule,
  GalleriaModule,
  OverlayModule,
  TabViewModule,
  TabMenuModule,
  TranslateModule,
  ProgressSpinnerModule,
  TooltipModuleBooking,
  InputNumberModule,
  CheckboxModule,
  RadioButtonModule,
  ChartModule,
  CalendarModule,
  DatetimeFormatterModule,
  SkeletonModule,
  KeyFilterModule,
  CurrencyConversionPipe,
  HistoryCurrencyPipe,
  InputMaskModule
];
const shareComponents = [
  ModalComponent,
  ToastComponent,
  ShowImageModalComponent,
  TabMenuComponent,
  CustomizePaginationComponent,
  PriceFormatComponent,
  CommonIconText,
  StarComponent,
  LoadingComponent,
  InputPhoneNumberComponent,
  LineChartComponent,
  DoughnutChartComponent,
  CalendarDashboardComponent,
  TableEmptyComponent,
  UnderTestComponent,
  ButtonComponent,
  CalendarComponent,
  PaginationByUrlComponent,
  BannerReservationFlightComponent,
  FlightScheduleComponent,
  FareRuleComponent,
  BookingInfoComponent,
  ContactInfoComponent,
  SeatSelectionComponent,
  ExtraBaggageComponent,
  MealSelectionComponent,
  SuggestSearchComponent,
  PassengersInfoComponent,
  PopupFlightItineraryComponent,
  PopupCancelBookingComponent,
  PreviewMediaComponent,
  BaggageDetailsComponent,
  CarouselComponent,
  TimePickerComponent,
  PriceFormatUsdComponent
];
@NgModule({
  declarations: [sharedPipes, shareComponents],
  imports: [CommonModule, shareModules],
  exports: [CommonModule, sharedPipes, shareComponents, shareModules],
  providers: [shareProviders],
})
export class SharedModule {}
