<div class="!mt-2 border-1 border-palette-gray-200 rounded-lg bg-white !p-4 font-inter">
    <div (click)="handleClickExpandCollapse(expandBaggage)" (mouseover)="handleMouseOverLeave(expandBaggage, true)" (mouseleave)="handleMouseOverLeave(expandBaggage, false)" 
        class="flex items-center justify-between cursor-pointer">
        <div class="flex items-center !gap-4">
            <img class="!h-[56px] aspect-square" src="assets/images/flight-payment/Baggage.svg" alt="">
            <p [ngClass]="{'!font-extrabold':expandBaggage.isMouseOver}" class="text-lg font-semibold text-palette-gray-900">
                {{'FLIGHT.EXTRA_BAGGAGE.EXTRA_BAGGAGE' | translate}}
                <span class="font-inter text-sm !font-normal" *ngIf="count > 0 && !expandBaggage.isExpand">({{ count }} {{ 'FLIGHT.EXTRA_BAGGAGE_SELECTED' | translate }})</span>
            </p>
        </div>
        <i [ngClass]="{'sctr-icon-chevron-down':expandBaggage.isExpand, 'sctr-icon-chevron-right':!expandBaggage.isExpand, 'font-extrabold':expandBaggage.isMouseOver}"
            class="text-xl leading-5 text-palette-gray-500"></i>
    </div>
    <div @slideDownUp *ngIf="expandBaggage.isExpand"
        class="{{dataConvert.length > 1 ? '!grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4' : ''}} !mt-4">
        <ng-container *ngIf="dataConvert.length; else baggageNotIncluded">
            <ng-container *ngFor="let baggagesSegment of dataConvert">
                <div class="col-span-1 !p-4 border border-palette-gray-200 bg-palette-gray-50 rounded-md">
                    <div class="flex items-center !gap-2 text-base !font-medium text-palette-gray-900">
                        <span>{{ baggagesSegment[0].origin_iata_code }}</span>
                        <i class="sctr-icon-arrow-narrow-right text-[20px]"></i>
                        <span>{{ baggagesSegment[0].destination_iata_code }}</span>
                    </div>
                    <div class="flex flex-col !gap-2 !mt-2">
                        <div *ngFor="let baggage of baggagesSegment"
                            class="flex justify-between !gap-2 text-sm">
                            <div class="relative flex-1">
                                <div class="line-clamp-1 text-sm text-palette-gray-700 max-w-full capitalize"
                                    [isTruncated]="true"
                                    [tooltip]="('COMMON.' + baggage?.title?.toUpperCase() | translate) + ' ' + toFirstUppercase(baggage?.last_name) + ' ' + toFirstUppercase(baggage?.middle_and_first_name)">
                                    {{ 'COMMON.' + baggage?.title?.toUpperCase() | translate }} {{ baggage?.last_name | lowercase }} {{ baggage?.middle_and_first_name | lowercase }}
                                </div>
                            </div>
                            <span class="font-medium text-palette-gray-900">
                                <ng-container *ngIf="baggage?.description && (baggage?.description?.toUpperCase()?.includes('OVERSIZE') ||
                                    baggage?.description?.toUpperCase()?.includes('HLQK')); else normalWeight">
                                    {{ 'COMMON.OVERSIZE' | translate}} {{baggage.weight}}{{baggage?.unit}}
                                </ng-container>
                                <ng-template #normalWeight>
                                    {{ baggage?.weight}}{{baggage?.unit}}
                                </ng-template>
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #baggageNotIncluded>
            <div class="text-palette-gray-700 text-sm font-semibold">{{ 'COMMON.NOT_INCLUDED' | translate }}</div>
        </ng-template>
    </div>
</div>