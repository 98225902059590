import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, catchError, finalize, of, takeUntil, timeout } from 'rxjs';
import { FlightHelpdeskControllerService } from '@booking/angular-booking-admin-service'
import { TIME_OUT_REQUEST } from '@shared/constant';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { convertToUserTimeZone, getUTCOffset } from '@shared/utils/helper';
import { environment } from '@env/environment';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss'],
})
export class ViewDetailsComponent {
 bookingId: string;
     isLoading: boolean = true;
     flightDetail: any;
     currentModeView = 'DEPART';
     currentSegments: any;
     currentSegmentType: string;
     currentFareRule: any;
     fareRuleVi: string;
     resource: string;
     isShowChangeRequestHistoryPopup: boolean = false;
     changeRequestHistory: any;
     titleMap: any;
     pathChangeRequest: string = 'admin/flight-admin/booking-helpdesk/change-request';
     pageNum: number;
     pageSize: number;
     isShowNoteUpdates: boolean = false;
     noteUpdate: string = '';
     isShowDiscardPopup: boolean = false;
     notes: any[];
     clickedConfirmNoteUpdates: boolean = false;
     isUpdateNote: boolean = false;
     baseUrl = environment.BASE_PATH;
     onDestroy$: Subject<void> = new Subject();
 
     constructor(
         private route: ActivatedRoute,
         private flightHelpdeskControllerService: FlightHelpdeskControllerService,
         private toastMessageService: ToastMessageService,
         private translateService: TranslateService,
     ) { }
 
     ngOnInit() {
         this.initData();
         this.bookingId = this.route.snapshot.paramMap.get('bookingId')!;
         this.callGetBookingDetail();
     }
 
     ngOnDestroy() {
         this.onDestroy$.next();
         this.onDestroy$.complete();
     }
 
     initData() {
         const pageNum = this.route.snapshot.queryParamMap.get('page-num');
         const pageSize = this.route.snapshot.queryParamMap.get('page-size');
         this.pageNum = pageNum ? +pageNum : 1;
         this.pageSize = pageSize ? +pageSize : 10;
 
         this.titleMap = {
             ADDITIONAL_SERVICES_PURCHASING: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.SERVICES_PURCHASE'),
             CANCEL_BOOKING: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.CANCEL_BOOKING'),
             CHANGE_PASSENGER_NAME: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.CHANGE_PASSENGER_NAME'),
             RESCHEDULE_FLIGHT: this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.CHANGE_REQUEST.RESCHEDULE_FLIGHT'),
         };
 
         this.changeRequestHistory = [
             {
                 title: "RESCHEDULE_FLIGHT",
                 detail: "test 123",
                 requested_at: "2024-06-21T10:59:47.569049897",
                 status: "Solved",
                 id: "123",
             },
             {
                 title: "RESCHEDULE_FLIGHT",
                 detail: "test 456",
                 requested_at: "2024-06-21T11:00:05.906078408",
                 status: "Open",
                 id: "456",
             },
             {
                 title: "RESCHEDULE_FLIGHT",
                 detail: "ádđssss",
                 requested_at: "2024-06-21T11:00:53.668189571",
                 status: "Open",
                 id: "789",
             },
             {
                 title: "RESCHEDULE_FLIGHT",
                 detail: "Test description",
                 requested_at: "2024-06-21T11:05:53.668189571",
                 status: "Open",
                 id: "012",
             }
         ];
     }
 
     public callGetBookingDetail = () => {
         this.flightHelpdeskControllerService.searchListBookingDetails(this.bookingId)
             .pipe(
                 timeout(TIME_OUT_REQUEST),
                 takeUntil(this.onDestroy$),
                 catchError((error) => {
                     if (error.name === 'TimeoutError') {
                         this.toastMessageService.showError('TIME_OUT_REQUEST');
                     } else if (error.status === 0) {
                         this.toastMessageService.showError('ERR_INTERNET_DISCONNECTED');
                     } else {
                         this.toastMessageService.showError(error.error.error);
                     }
                     return of(null);
                 }),
                 finalize(() => {
                     this.isLoading = false;
                 })
             )
             .subscribe((response: any) => {
                 if (response.success) {
                     this.flightDetail = response.data;
                     const index = this.currentModeView === 'DEPART' ? 0 : 1;
                     const itinerary = this.flightDetail.flight_info?.itineraries[index];
                     if (itinerary) {
                         this.currentSegments = itinerary.segments;
                         this.currentSegmentType = itinerary.segment_type;
                         this.currentFareRule = itinerary.fare_rule;
                         this.fareRuleVi = itinerary.fare_rule_vi;
                         this.resource = itinerary.resource
                     }
                     this.bookingId = this.flightDetail?.booking_info.reservation_code;
                     this.notes = this.flightDetail?.notes;
                 }
             });
     };
 
     changeViewMode(mode: string) {
         this.currentModeView = mode;
         // Re init data when mode changes
         const index = this.currentModeView === 'DEPART' ? 0 : 1;
         const itinerary = this.flightDetail.flight_info?.itineraries[index];
         if (itinerary) {
             this.currentSegments = itinerary.segments;
             this.currentSegmentType = itinerary.segment_type;
             this.currentFareRule = itinerary.fare_rule;
             this.fareRuleVi = itinerary.fare_rule_vi;
             this.resource = itinerary.resource
         }
     }
 
     onClickViewHistory() {
         this.isShowChangeRequestHistoryPopup = true;
     }
 
     closeChangeRequestHistoryPopup() {
         this.isShowChangeRequestHistoryPopup = false;
     }
 
     formatDateTime(dateTime: string): string {
         return moment(dateTime).format('HH:mm, MMM D, YYYY');
     }
 
     changeRequestStatusColor(status: string) {
         switch (status.toUpperCase()) {
             case 'SOLVED':
                 return 'text-palette-emerald-600 bg-palette-emerald-50';
             case 'OPEN':
                 return 'text-palette-gray-700 bg-palette-gray-100';
             default:
                 return '';
         }
     }
 
     convertUserTimeZone(utcTime: string): string {
         return convertToUserTimeZone(utcTime)
     }
 
     getUTCUser(): string {
         return getUTCOffset()
     }
 
     openNoteUpdates() {
         this.isShowNoteUpdates = true;
     }
     closeNoteUpdates() {
         if (this.noteUpdate) {
             this.isShowDiscardPopup = true;
         } else {
             this.isShowNoteUpdates = false;
             this.clickedConfirmNoteUpdates = false;
         }
     }
 
     onConfirmNoteUpdates() {
         if (this.noteUpdate.length && this.noteUpdate.length <= 254) {
             this.isUpdateNote = true;
             const body = {
                 note: this.noteUpdate
             }
             this.flightHelpdeskControllerService.addFlightNote(body, this.bookingId)
                 .pipe(
                     timeout(TIME_OUT_REQUEST),
                     takeUntil(this.onDestroy$),
                     catchError((error) => {
                         if (error.name === 'TimeoutError') {
                             this.toastMessageService.showError('TIME_OUT_REQUEST');
                         } else if (error.status === 0) {
                             this.toastMessageService.showError('ERR_INTERNET_DISCONNECTED');
                         } else {
                             this.toastMessageService.showError(error.error.error);
                         }
                         return of(null);
                     }),
                     finalize(() => {
                         this.isUpdateNote = false;
                     })
                 )
                 .subscribe((response: any) => {
                     if (response.success) {
                         this.notes = response?.data?.notes;
                         this.noteUpdate = '';
                         this.clickedConfirmNoteUpdates = false;
                         this.isShowNoteUpdates = false;
                         this.toastMessageService.showSuccess(this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.NOTE_UPDATE_SUCCESS'));
                     } else {
                         this.toastMessageService.showError(response.error.message);
                     }
                 });
         } else {
             this.clickedConfirmNoteUpdates = true;
         }
     }
 
     onKeepEditing() {
         this.isShowDiscardPopup = false;
     }
     onDiscard() {
         this.isShowDiscardPopup = false;
         this.isShowNoteUpdates = false;
         this.clickedConfirmNoteUpdates = false;
         this.noteUpdate = '';
     }
 
     replaceWithDefaultAvatar(event: any): void {
         event.target.src = 'assets/images/default_user_avatar.png';
     }
 }
 