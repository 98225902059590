export const environment = {
  production: false,
  baseURL: 'https://api-dev-tmtrav.tma-swerp.com',
  BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com',
  BASE_API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  TRAVEL_ASSIST_URL: 'https://tmtrav-travel-dev.tma-swerp.com',
  BOOKING_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  FLIGHT_URL: 'https://tmtrav-flight-dev.tma-swerp.com',
  ECOMMERCE_FOOD_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  ECOMMERCE_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  SOCIAL_ADMIN_APP_URL: 'https://admin-center-tmtrav.tma-swerp.com/',
  TRAVEL_ADMIN_ASSIST_URL: 'https://tour-admin-tmtrav.tma-swerp.com/',
  HOTEL_ADMIN_URL: 'https://hotel-admin-tmtrav.tma-swerp.com/',
  CAR_RENTAL_ADMIN_URL: 'https://car-admin-tmtrav.tma-swerp.com/',
  ECOMMERCE_FOOD_ADMIN_URL: 'https://restaurant-admin-tmtrav.tma-swerp.com/',
  ECOMMERCE_ADMIN_URL: 'https://shop-admin-tmtrav.tma-swerp.com/',
  ADMIN_APP_URL: 'https://admin-dev.tma-swerp.com',
  PROPERTY_DATA_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-property-data',
  RESERVATION_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-reservation',
  RATING_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/rating',
  PROPERTY_SEARCH_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-property-search',
  USER_AND_GUEST_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-user-data',
  FAMOUS_SPOT_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-famous-spot',
  BOOKING_GENERIC_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-generic',
  BOOKMARK_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/bookmarks',
  SHIPMENT_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/shipment',
  TM_TRAV_MAP_LOCATION_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/map-location',
  STORAGE_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/storage',
  STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE:
    'https://api-dev-tmtrav.tma-swerp.com/storage/files/web',
  BOOKING_ADMIN_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-admin',
  BOOKING_USER_DATA_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-user-data',
  ECOMMERCE_WALLET_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/ecommerce-wallet',
  BOOKING_FLIGHT_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-flight-data',
  BOOKING_FLIGHT_ORDERS_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-flight-orders',
  TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status',
  USER_PROFILE: 'user_profile',
  support_languages: ['en', 'vi'],
  support_currency: ['USD', 'VND'],
  USER_UUID: 'user_uuid_generate',
  ENVIRONMENT: 'dev',
  PRODUCT_NAME: 'Soctrip Admin',
  SESSION_ID: 'session_id',
  HOTEL_REFUND_HUB_URL:
    'https://tmtrav-shop-admin-dev.tma-swerp.com/refund-hub/FLIGHT',
  FLIGHT_VOUCHER_HUB_URL:
    'https://tmtrav-voucher-hub-dev.tma-swerp.com/voucher-module/FLIGHT/voucher-management',
  API_TIMEOUT: 10000,
  FLIGHT_BANNER: 'https://tmtrav-banner-dev.tma-swerp.com/flight/flight-hero-banner',
  languages: ['en', 'vi'],
  CHAT_MODULE: "FLIGHT",
  CHAT_FAQ_PORTAL_URL: 'https://chat-faq-admin.tma-swerp.com',
  REGIONAL_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/regional',
  BOOKING_FLIGHT_ORDERS_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-flight-orders',
};
