<div class="w-full">
    <span class="p-input-icon-right">
        <input
            (click)="onOpenCalendarPanel($event)"
            [value]="inputString"
            readonly
            class="fl-input-1 hover:cursor-pointer"
            type="text"
            pInputText
        />
        <i (click)="onOpenCalendarPanel($event)" 
            class="sctr-icon-calendar text-palette-gray-500 text-xl
                   leading-4 hover:cursor-pointer">
        </i>
    </span>

    <p-overlayPanel #overlayPanelCalendar (onHide)="onOutsideClick()">
        <div class="flex !gap-4">
            <div class="flex flex-col !gap-1">
                <label
                    *ngFor="let option of optionFilter"
                    (click)="onSelectedOption(option)"
                    class="cursor-pointer py-1 text-sm font-medium"
                    [ngClass]="
                        selectedOption === option.value
                        ? 'text-palette-blue-600'
                        : 'hover:text-palette-blue-500'"
                    >
                    {{ "primeng." + option.value | translate | sentenceCase }}
                </label>
            </div>
            <div>
                <p-calendar 
                    class="fl-calendar-1"
                    [(ngModel)]="calendarValue"
                    [inline]="true"
                    [view]="view"
                    [maxDate]="today"
                    [selectionMode]="selectionMode"
                    [firstDayOfWeek]="lang === 'vi' ? 1 : 0"
                    (ngModelChange)="onSelectedDate($event)"
                    [numberOfMonths]="2">
                    <ng-template pTemplate="date" let-date>
                        <div
                            class="flex items-center justify-center
                                   !h-[38px] w-full
                                   hover:!bg-palette-blue-400
                                   hover:!text-white"
                            [ngClass]="{
                                'start-date': isStartDate(date),
                                'end-date': isEndDate(date),
                                'in-range': isInRange(date)
                            }"
                        >
                            {{date.day}}
                        </div>
                    </ng-template>
                </p-calendar>
                <div class="!mt-3 flex justify-between">
                    <div class="flex items-center !gap-1">
                        <div class="max-w-[150px]">
                            <input
                                [value]="inputStartDate"
                                readonly class="fl-input-1 hover:cursor-pointer"
                                type="text"
                                pInputText
                            />
                        </div>
                        <span>-</span>
                        <div class="max-w-[150px]">
                            <input
                                [value]="inputEndDate"
                                readonly class="fl-input-1 hover:cursor-pointer"
                                type="text"
                                pInputText
                            />
                        </div>
                    </div>
                    <div class="flex !gap-2">
                        <button
                            (click)="onCancel()"
                            class="!h-11 !w-[88px] rounded-lg border bg-white !px-5
                                    !py-3 font-inter !text-sm font-semibold text-palette-gray-900">
                            {{ "COMMON.CANCEL" | translate }}
                        </button>
                        <button
                            (click)="onApply()"
                            class="!h-11 !min-w-[88px] rounded-lg border 
                                 bg-palette-blue-600 !px-5 !py-3 font-inter !text-sm font-semibold text-white">
                            {{ "COMMON.APPLY" | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </p-overlayPanel>
</div>
<app-toast [keyToast]="'CALENDAR_ERROR'"></app-toast>