import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-price-format-usd',
  templateUrl: './price-format-usd.component.html',
  styleUrls: ['./price-format-usd.component.scss']
})
export class PriceFormatUsdComponent {
  @Input() price: number;
  @Input() styleClass: string;
  currentCurrency: any;

  constructor() {}
  ngOnInit(): void {}

  formatPrice(price: number): string {
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(price);
    return formattedPrice;
  }
}
