<div class="rounded-lg bg-white !p-6 min-[500px]:min-w-[460px] font-inter !mx-2">
    <div class="w-full flex justify-between !gap-4 !pb-6">
        <h2 class="text-palette-gray-900 text-lg font-semibold">
            {{ 'UPDATE_BOOKING_ISSUE.FLIGHT_ITINERARY' | translate }}</h2>
        <button class="rounded-lg hover:bg-palette-gray-100 !w-7 !h-7" (click)="onClickCancel()">
            <i class="sctr-icon-x-close text-xl"></i>
        </button>
    </div>
    <div class="!mb-2 flex flex-col !gap-4 text-palette-gray-900 text-base font-medium custom-checkbox"
        [ngClass]="{'invalid': isValidateItinerary && !isChosenDepart && !isChosenReturn}">
        <p class="text-palette-gray-900 text-sm">
            {{ 'UPDATE_BOOKING_ISSUE.FLIGHT_ITINERARY_CONTENT' | translate }}
        </p>
        <!-- DEPART -->
        <div (click)="onClickItinerary('DEPART')" class="box-itinerary {{isChosenDepart? '!bg-palette-blue-50 !border-palette-blue-600': ''}}">
            <div class="flex justify-between items-center !gap-3">
                <div class="flex flex-col min-[500px]:flex-row min-[500px]:items-center !gap-2">
                    <div class="rounded bg-palette-blue-600 !px-2 py-[2px] text-white text-xs font-medium min-w-[80px] w-[80px] text-center">
                        {{ 'COMMON.DEPART' | translate }}
                    </div>
                    <p class="text-sm text-palette-gray-900 font-semibold">
                        {{ depart?.segments?.[0]?.departure?.city_name || '--' }}
                        <i class="sctr-icon-arrow-right text-sm inline-block"></i>
                        {{ depart?.segments?.[depart?.segments?.length-1]?.arrival?.city_name || '--' }}
                    </p>
                </div>
                <div class="rounded-full !min-w-5 !min-h-5 !w-5 !h-5 border flex justify-center items-center"
                    [ngClass]="isChosenDepart ? 'bg-palette-blue-600 border-palette-blue-600' : 'bg-white border-palette-gray-300'">
                    <i class="sctr-icon-check text-white text-xs font-semibold"></i>
                </div>
            </div>
            <div class="!mt-2 flex items-center !gap-1">
                <ng-container *ngIf="depart?.segments?.[0] as segment">
                    <img 
                      loading="lazy"
                      class="rounded-full max-w-[20px] max-h-[20px] !w-5 !h-5"
                      [src]="segment?.operating_logo_id ? baseImage + '/' + segment?.operating_logo_id + '.webp' : segment?.operating_logo_url"
                      (error)="replaceWithDefaultImage($event)"
                      alt="Logo"
                    >
                    <p class="text-sm text-palette-gray-900">{{ depart?.segments?.[0]?.operating_name }} {{ depart?.segments?.[0]?.operating_code }}{{ depart?.segments?.[0]?.operating_number }}</p>
                </ng-container>
            </div>
            <p class="!mt-1 text-sm text-palette-gray-500 flex items-center">
                {{ depart?.segments?.[0]?.time_departure_at?.slice(0,5) || '--:--' }} - 
                {{ depart?.segments?.[depart?.segments?.length-1]?.time_arrival_at.slice(0,5) || '--:--' 
                }}<span class="text-xs inline-block text-palette-red-600 !mr-1">
                    {{ numStopDateDepart > 0 ? '+' + numStopDateDepart : '' }}
                </span> 
                 • {{ depart?.segments?.[0]?.date_departure_at | dateFormat }}
            </p>
        </div>
        <!-- RETURN -->
        <div (click)="onClickItinerary('RETURN')" class="box-itinerary {{isChosenReturn? '!bg-palette-blue-50 !border-palette-blue-600': ''}}">
            <div class="flex justify-between items-center !gap-3">
                <div class="flex flex-col min-[500px]:flex-row min-[500px]:items-center !gap-2">
                    <div class="rounded bg-palette-blue-600 !px-2 py-[2px] text-white text-xs font-medium min-w-[80px] w-[80px] text-center">
                        {{ 'COMMON.RETURN' | translate }}
                    </div>
                    <p class="text-sm text-palette-gray-900 font-semibold">
                        {{ return?.segments?.[0]?.departure?.city_name || '--' }}
                        <i class="sctr-icon-arrow-right text-sm inline-block"></i>
                        {{ return?.segments?.[return?.segments?.length-1]?.arrival?.city_name || '--' }}
                    </p>
                </div>
                <div class="rounded-full !min-w-5 !min-h-5 !w-5 !h-5 border flex justify-center items-center"
                    [ngClass]="isChosenReturn ? 'bg-palette-blue-600 border-palette-blue-600' : 'bg-white border-palette-gray-300'">
                    <i class="sctr-icon-check text-white text-xs font-semibold"></i>
                </div>
            </div>
            <div class="!mt-2 flex items-center !gap-1">
                <ng-container *ngIf="return?.segments?.[0] as segment">
                    <img 
                      loading="lazy"
                      class="rounded-full max-w-[20px] max-h-[20px] !w-5 !h-5"
                      [src]="segment?.operating_logo_id ? baseImage + '/' + segment?.operating_logo_id + '.webp' : segment?.operating_logo_url"
                      (error)="replaceWithDefaultImage($event)"
                      alt="Logo"
                    >
                    <p class="text-sm text-palette-gray-900">{{ return?.segments?.[0]?.operating_name }} {{ return?.segments?.[0]?.operating_code }}{{ return?.segments?.[0]?.operating_number }}</p>
                </ng-container>
            </div>
            <p class="!mt-1 text-sm text-palette-gray-500 flex items-center">
                {{ return?.segments?.[0]?.time_departure_at?.slice(0,5) || '--:--' }} - 
                {{ return?.segments?.[return?.segments?.length-1]?.time_arrival_at.slice(0,5) || '--:--' 
                }}<span class="text-xs inline-block text-palette-red-600 !mr-1">
                    {{ numStopDateReturn > 0 ? '+' + numStopDateReturn : '' }}
                </span> 
                 • {{ return?.segments?.[0]?.date_departure_at | dateFormat }}
            </p>
        </div>
        <div *ngIf="isValidateItinerary && !isChosenDepart && !isChosenReturn"
            class="text-palette-red-600 text-sm flex items-center !gap-1">
            <i class="sctr-icon-alert-circle text-palette-red-600"></i>
            {{ 'UPDATE_BOOKING_ISSUE.FLIGHT_ITINERARY_WARNING' | translate }}
        </div>
    </div>
    <div class="flex justify-end items-center !gap-3 !pt-6">
        <button (click)="onClickCancel()"
            class="rounded-lg border border-palette-gray-300 bg-white hover:!bg-palette-gray-200 text-palette-gray-900 text-sm font-semibold !px-5 !py-3 transition-all">
            {{ 'COMMON.CANCEL' | translate }}
        </button>
        <button (click)="onClickContinue()"
            class="rounded-lg bg-palette-blue-600 hover:!bg-palette-blue-500 text-white text-sm font-semibold !px-5 !py-3 transition-all">
            {{ 'COMMON.CONTINUE' | translate }}
        </button>
    </div>
</div>