import { Component, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-passengers-info',
  templateUrl: './passengers-info.component.html',
  styleUrls: ['./passengers-info.component.scss'],
  animations: [
    trigger('slideDownUp', [
      state('void', style({ height: '0px', overflow: 'hidden', opacity: 0 })),
      state('*', style({ height: '*', overflow: 'hidden', opacity: 1 })),
      transition(':enter', [animate('300ms ease-out')]),
      transition(':leave', [animate('300ms ease-in')]),
    ]),
    trigger('slideInOut', [
      state('void', style({ opacity: 0 })),
      state('*', style({ opacity: 1 })),
      transition(':enter', [animate('300ms ease-in')]),
      transition(':leave', [animate('300ms ease-out')]),
    ]),
  ],
})
export class PassengersInfoComponent {
  @Input() passengerList: any[];
  @Input() currentModeView: string;
  
  isExpand: boolean = false;
  isMouseOverExpand: boolean = false;
  totalAdults: number = 0;
  totalChildren: number = 0;
  totalInfant: number = 0;
  
  ngOnInit() {
    this.initTotalData();
  }

  initTotalData() {
    this.totalAdults = this.countPassengerByType(this.passengerList, ['ADULT', 'ADT']);
    this.totalChildren = this.countPassengerByType(this.passengerList, ['CHILD', 'CHD', 'CHILDREN']);
    this.totalInfant = this.countPassengerByType(this.passengerList, ['INFANT', 'INF ']);
  }
  
  countPassengerByType(passengerList: any[], types: string[]) {
    return passengerList.filter(
      (passenger) => types.includes(passenger.passenger_type.toUpperCase())
    ).length;
  }

  handleClickExpandCollapse() {
    this.isExpand = !this.isExpand;
  }
}
