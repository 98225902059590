<div class="rounded-lg bg-white flex flex-col min-w-[380px] max-h-[calc(90vh-90px)] max-w-[96vw] w-[996px]">
    <!-- HEADER -->
    <div class="flex justify-between items-center !py-5 !pl-6 !pr-4">
        <h3 class="text-palette-gray-900 text-lg font-semibold">
            {{ 'COMMON.CANCEL_BOOKING' | translate }}
        </h3>
        <button type="button"
            (click)="onClickCancel()"
            class="flex justify-center items-center !p-1 !w-8 !h-8 rounded-lg hover:bg-palette-gray-200">
            <i class="sctr-icon-x-close text-xl leading-5 text-palette-gray-500"></i>
        </button>
    </div>
    <!-- BODY -->
    <div class="flex-1 !px-6 !pb-6 overflow-y-auto">
        <div class="flex flex-col !gap-4 rounded-lg">
            <!-- PASSENGERS SELECTED -->
            <div *ngIf="selectedPassenger" class="rounded-lg border border-palette-gray-200 !p-4 !pt-3">
                <h3 *ngIf="type === 'REQUEST_REFUND'" class="text-palette-gray-900 text-base font-semibold !mb-3">
                    {{ 'FLIGHT.PAYMENT.PASSENGER' | translate }}</h3>
                <h3 *ngIf="type !== 'REQUEST_REFUND'" class="text-palette-gray-900 text-base font-semibold !mb-3">
                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.PASSENGERS_SELECTED' | translate }}</h3>
                <div class="flex flex-col lg:flex-row !gap-6">
                    <div *ngIf="selectedPassenger.selectedDepartPassenger.length && (!itinerary || itinerary?.isChosenDepart)" 
                        class="flex-1">
                        <div class="!p-0.5 rounded bg-palette-gray-100 flex items-center gap-3 w-full !mb-3">
                            <div class="flex justify-center items-center !px-2 !py-1 bg-palette-blue-600 rounded text-xs font-semibold text-white uppercase min-w-[85px]">
                                {{ 'COMMON.DEPART' | translate }}
                            </div>
                            <div class="text-palette-gray-700 text-sm font-medium">
                                <span>{{ itineraries[0]?.segments[0]?.departure?.city_name }}</span>
                                <span> -> </span>
                                <span>{{ itineraries[0]?.segments[itineraries[0]?.segments?.length-1]?.arrival?.city_name }}</span>
                            </div>
                        </div>
                        <div class="flex flex-col gap-2">
                            <ng-container *ngFor="let passenger of selectedPassenger.selectedDepartPassenger">
                                <p class="text-palette-gray-900 text-sm uppercase">
                                    {{passenger?.title}}. {{passenger?.last_name}} {{passenger?.middle_and_first_name}}
                                    <span *ngIf="passenger?.passenger_type === 'Infant' || passenger?.passenger_type === 'INF'" 
                                        class="rounded-full !px-2 !py-1 text-palette-red-600 font-medium text-xs bg-palette-red-50 first-letter:uppercase">
                                        {{ 'COMMON.INFANT' | translate }}</span>
                                </p>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="selectedPassenger.selectedDepartPassenger.length && (!itinerary || itinerary?.isChosenDepart)
                        && selectedPassenger.selectedReturnPassenger.length && itinerary?.isChosenReturn" 
                        class="flex flex-col items-center">
                        <div class="h-[1px] lg:h-full border w-full lg:w-[1px]"></div>
                    </div>
                    <div *ngIf="selectedPassenger.selectedReturnPassenger.length && itinerary?.isChosenReturn" 
                        class="flex-1">
                        <div class="!p-0.5 rounded bg-palette-gray-100 flex items-center gap-3 w-full !mb-3">
                            <div class="flex justify-center items-center !px-2 !py-1 bg-palette-blue-600 rounded text-xs font-semibold text-white uppercase min-w-[85px]">
                                {{ 'COMMON.RETURN' | translate }}
                            </div>
                            <div class="text-palette-gray-700 text-sm font-medium">
                                <span>{{ itineraries[1]?.segments[0]?.departure?.city_name }}</span>
                                <span> -> </span>
                                <span>{{ itineraries[1]?.segments[itineraries[1]?.segments?.length-1]?.arrival?.city_name }}</span>
                            </div>
                        </div>
                        <div class="flex flex-col gap-2">
                            <ng-container *ngFor="let passenger of selectedPassenger.selectedReturnPassenger">
                                <p class="text-palette-gray-900 text-sm uppercase">
                                    {{passenger?.title}}. {{passenger?.last_name}} {{passenger?.middle_and_first_name}}
                                    <span *ngIf="passenger?.passenger_type === 'Infant' || passenger?.passenger_type === 'INF'" 
                                        class="rounded-full !px-2 !py-1 text-palette-red-600 font-medium text-xs bg-palette-red-50 first-letter:uppercase">
                                        {{ 'COMMON.INFANT' | translate }}</span>
                                </p>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <!-- REQUEST DETAILS -->
            <div *ngIf="type !== 'REQUEST_REFUND'" class="rounded-lg border border-palette-blue-600 !px-4 !py-3 bg-palette-blue-50">
                <h3 class="text-palette-gray-900 text-base font-semibold">
                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.REQUEST_DETAILS' | translate }}</h3>
                <p class="text-palette-gray-600 text-xs">
                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.REQUEST_AT' | translate }} {{ flightDetail?.request_detail?.requested_at | dateFormat : true }}
                </p>
                <p *ngIf="flightDetail?.request_detail?.request_process?.length" class="text-palette-gray-700 text-sm !mt-3">
                    {{ flightDetail.request_detail.request_process[0]?.customer_note }}
                </p>
            </div>
            <div class="border border-palette-gray-200 !p-4 rounded-lg flex flex-col !gap-4">
                <!-- FLIGHT DETAILS -->
                <div class="flex flex-col !gap-3">
                    <h3 class="text-palette-gray-900 text-base font-semibold">{{ 'COMMON.FLIGHT_DETAILS' | translate }}</h3>
                    <ng-container *ngFor="let item of itineraries; let index = index">
                        <div *ngIf="(index === 0 && (!itinerary || itinerary?.isChosenDepart)) || 
                            index === 1 && itinerary?.isChosenReturn" 
                            class="!p-2 rounded bg-palette-gray-100">
                            <!-- TITLE -->
                            <div (click)="toggleSection('state', index)" class="!p-2 flex justify-between items-center !gap-2 hover:bg-palette-gray-200 cursor-pointer">
                                <div class="flex items-center !gap-3">
                                    <div class="rounded !px-2 !py-1 bg-palette-blue-600 text-white text-xs font-semibold uppercase min-w-[85px] flex justify-center items-center">
                                        {{ (index === 0 ? 'COMMON.DEPART' : 'COMMON.RETURN') | translate }}
                                    </div>
                                    <div class="text-palette-gray-700 text-sm font-medium">
                                        <span> {{ item.segments[0]?.departure?.city_name }}</span>
                                        <span> -> </span>
                                        <span> {{ item.segments[item.segments.length - 1]?.arrival?.city_name }}</span>
                                    </div>
                                </div>
                                <div class="flex items-center !gap-2">
                                    <div class="font-inter text-sm font-semibold text-palette-gray-900">
                                        <app-price-format [price]="priceDetails?.price_details[index]?.final_price"></app-price-format>
                                    </div>
                                    <i class="{{sectionVisibility[index].state ? 'sctr-icon-chevron-down' : 'sctr-icon-chevron-right'}} text-xl leading-5 text-palette-gray-500"></i>
                                </div>
                            </div>
                            <!-- DETAILS & PRICE -->
                            <div *ngIf="sectionVisibility[index].state" class="!mt-2 flex flex-col lg:flex-row !gap-2">
                                <div class="flex-1 rounded-md bg-white !p-3">
                                    <ng-container *ngFor="let segment of item.segments; let first = first; let last = last">
                                        <div class="flex !gap-6">
                                            <div class="flex flex-col !gap-4 justify-between">
                                                <div class="text-right">
                                                    <p class="text-sm font-medium text-palette-gray-700">{{ formatTime(segment.time_departure_at) }}</p>
                                                    <p class="text-xs text-palette-gray-500 font-normal">{{ segment.date_departure_at | dateFormat }}</p>
                                                </div>
                                                <div class="flex flex-row-reverse text-right w-[80px] text-sm font-normal text-palette-gray-700">
                                                    {{formatDuration(segment.duration, true)}}
                                                </div>
                                                <div class="text-right">
                                                    <p class="text-sm font-medium text-palette-gray-700">{{ formatTime(segment.time_arrival_at) }}</p>
                                                    <p class="text-xs text-palette-gray-500 font-normal">{{ segment.date_arrival_at | dateFormat }}</p>
                                                </div>
                                            </div>
                                            <div class="flex flex-col items-center">
                                                <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                                                <div class="h-full border border-dashed w-[1px]"></div>
                                                <div class="bg-palette-gray-300 border rotate-45 !w-2 !h-2"></div>
                                            </div>
                                            <div class="flex flex-col !gap-4">
                                                <div>
                                                    <p class="text-sm font-medium text-palette-gray-700">
                                                        {{segment.departure.city_name}} ({{segment.departure.iata_code}})</p>
                                                    <p class="text-xs text-palette-gray-500 font-normal">
                                                        {{segment.departure.airport_name}} {{segment.departure.terminal}}</p>
                                                </div>
                                                <div class="flex flex-col !gap-2">
                                                    <div class="flex items-center !gap-2">
                                                        <!-- <img class="" src="{{item.operating_logo_url}}" alt=""> -->
                                                        <img 
                                                            *ngIf="segment.operating_logo_id"
                                                            loading="lazy"
                                                            class="rounded-md max-w-[70px] max-h-[30px]"
                                                            src="{{ baseImage }}/{{ segment.operating_logo_id }}.webp"
                                                            (error)="replaceWithDefaultImage($event)" alt="">
                                                        <img 
                                                            *ngIf="!segment.operating_logo_id"
                                                            loading="lazy"
                                                            class="rounded-md max-w-[70px] max-h-[30px]"
                                                            [src]="segment.operating_logo_url"
                                                            (error)="replaceWithDefaultImage($event)"
                                                            alt=""
                                                        >
                                                        <div class="text-sm font-normal text-palette-gray-700">
                                                            {{segment.operating_name}} {{segment.operating_code}}{{segment.operating_number}}
                                                        </div>
                                                    </div>
                                                    <div *ngIf="segment.baggage_allowance">
                                                        <div class="flex flex-col !gap-2.5">
                                                            <div class="flex items-center !gap-2">
                                                                <i class="sctr-icon-luggage-02 text-xl text-palette-gray-500"></i>
                                                                <span class="font-inter text-sm font-normal text-palette-gray-900">
                                                                    {{ 'FLIGHT.SEARCH_RESULT.CABIN_BAGGAGE' | translate }} 
                                                                    {{ formatBaggages(segment.baggage_allowance?.cabin_baggage) }}
                                                                </span>
                                                            </div>
                                                            <div class="flex items-center !gap-2">
                                                                <i class="sctr-icon-luggage-01 text-xl text-palette-gray-500"></i>
                                                                <span class="font-inter text-sm font-normal text-palette-gray-900">
                                                                    {{ 'FLIGHT.SEARCH_RESULT.CHECKED_BAGGAGE' | translate }} 
                                                                    <ng-container *ngIf="formatBaggages(segment.baggage_allowance?.checked_baggage) == ''; else showBaggage">
                                                                        <span class="font-inter text-sm font-normal text-palette-gray-900 lowercase">
                                                                            {{'MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.NOT_INCLUDED'| translate}}
                                                                        </span>
                                                                    </ng-container>
                                                                    <ng-template #showBaggage>
                                                                        <span class="font-inter text-sm font-normal text-palette-gray-900">
                                                                            {{ formatBaggages(segment.baggage_allowance?.checked_baggage) }}
                                                                        </span>
                                                                    </ng-template>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p class="text-sm font-medium text-palette-gray-700">
                                                        {{segment.arrival.city_name}} ({{segment.arrival.iata_code}})</p>
                                                    <p class="text-xs text-palette-gray-500 font-normal">
                                                        {{segment.arrival.airport_name}} {{segment.arrival.terminal}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="!last" class="!px-3 !py-1 bg-palette-blue-50 rounded font-inter flex flex-col !gap-1 !my-3">
                                            <div class="text-xs font-medium text-palette-blue-light-600">
                                                {{ 'FLIGHT.VIEW_DETAILS_SIDEBAR.TRANSFER_IN' | translate: { syntax: segment.city_name_transfer, syntax2: formatDuration(segment.waiting_transfer, false) } }}
                                            </div>
                                            <div *ngIf="!segment.is_difference_terminal"
                                                class="text-xs !text-[10px] font-normal text-palette-orange-dark-600">
                                                {{'FLIGHT.VIEW_DETAILS_SIDEBAR.DIFFERENT_TERMINAL' | translate}}
                                            </div>
                                            <div *ngIf="segment.is_difference_terminal"
                                                class="text-xs !text-[10px] font-normal text-palette-orange-dark-600">
                                                {{'FLIGHT.VIEW_DETAILS_SIDEBAR.VISA_REQUIRED' | translate}}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="flex-1 !pl-4 !pr-8">
                                    <!-- ORIGINAL PRICE -->
                                    <div class="flex items-center gap-2">
                                        <h4 class="text-palette-gray-900 text-sm font-semibold">
                                            {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.ORIGINAL_PRICE' | translate }}</h4>
                                        <span class="flex justify-center items-center !w-4 !h-4 relative cursor-pointer">
                                            <i [tooltip]="('MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.ORIGINAL_PRICE_TOOLTIP' | translate)"
                                                [styleClass]="'text-palette-gray-700 text-sm font-normal min-w-[310px]'"
                                                class="sctr-icon-alert-circle text-base leading-4 text-palette-gray-600">
                                            </i>
                                        </span>
                                    </div>
                                    <!-- ORIGINAL PRICE LIST -->
                                    <div class="!mt-2 flex flex-col gap-1">
                                        <!-- Tickets -->
                                        <div class="flex justify-between items-center gap-2">
                                            <div class="flex items-center !gap-2">
                                                <p class="text-sm font-medium text-palette-gray-700">
                                                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.TICKETS' | translate }} 
                                                    ({{ (priceDetails?.price_details[index]?.num_of_adults ?? 0) + 
                                                        (priceDetails?.price_details[index]?.num_of_children ?? 0) + 
                                                        (priceDetails?.price_details[index]?.num_of_infants ?? 0) }})
                                                </p>
                                                <button (click)="toggleSection('tickets', index)" class="!w-5 !h-5 flex justify-center items-center rounded hover:bg-palette-gray-200">
                                                    <i class="{{sectionVisibility[index].tickets ? 'sctr-icon-chevron-down' : 'sctr-icon-chevron-right'}} 
                                                        text-base leading-4 text-palette-gray-500"></i>
                                                </button>
                                            </div>
                                            <div class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="priceDetails?.price_details[index]?.origin_adult_fare_price +
                                                    priceDetails?.price_details[index]?.origin_child_fare_price + 
                                                    priceDetails?.price_details[index]?.origin_infant_fare_price">
                                                </app-price-format>
                                            </div>
                                        </div>
                                        <div *ngIf="sectionVisibility[index].tickets" class="flex flex-col gap-0.5 !pl-4">
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_adults" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{'COMMON.ADULT' | translate:{ syntax: priceDetails?.price_details[index]?.num_of_adults > 1 ? 's': '' } }} x {{ priceDetails?.price_details[index]?.num_of_adults }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.origin_adult_fare_price"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_children" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{ (priceDetails?.price_details[index]?.num_of_children > 1 ?'COMMON.CHILDREN':'COMMON.CHILD') |translate }} x {{ priceDetails?.price_details[index]?.num_of_children }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.origin_child_fare_price"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_infants" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{'COMMON.INFANT' | translate }} x {{ priceDetails?.price_details[index]?.num_of_infants }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.origin_infant_fare_price"></app-price-format>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Ticket's taxes -->
                                        <div class="flex justify-between items-center gap-2">
                                            <div class="flex items-center !gap-2">
                                                <p class="text-sm font-medium text-palette-gray-700">
                                                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.TICKET_TAXES' | translate }} 
                                                    ({{ (priceDetails?.price_details[index]?.num_of_adults ?? 0) + 
                                                        (priceDetails?.price_details[index]?.num_of_children ?? 0) + 
                                                        (priceDetails?.price_details[index]?.num_of_infants ?? 0) }})
                                                </p>
                                                <button (click)="toggleSection('taxes', index)" class="!w-5 !h-5 flex justify-center items-center rounded hover:bg-palette-gray-200">
                                                    <i class="{{sectionVisibility[index].taxes ? 'sctr-icon-chevron-down' : 'sctr-icon-chevron-right'}} 
                                                        text-base leading-4 text-palette-gray-500"></i>
                                                </button>
                                            </div>
                                            <div class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="(priceDetails?.price_details[index]?.origin_adult_tax ?? 0) +
                                                    (priceDetails?.price_details[index]?.origin_child_tax ?? 0) + 
                                                    (priceDetails?.price_details[index]?.origin_infant_tax ?? 0)">
                                                </app-price-format>
                                            </div>
                                        </div>
                                        <div *ngIf="sectionVisibility[index].taxes" class="flex flex-col gap-0.5 !pl-4">
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_adults" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{'COMMON.ADULT' | translate:{ syntax: 2 > 1 ? 's': '' } }} x {{ priceDetails?.price_details[index]?.num_of_adults }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.origin_adult_tax"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_children" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{ (2>1 ?'COMMON.CHILDREN':'COMMON.CHILD') |translate }} x {{ priceDetails?.price_details[index]?.num_of_children }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.origin_child_tax"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_infants" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{'COMMON.INFANT' | translate }} x {{ priceDetails?.price_details[index]?.num_of_infants }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.origin_infant_tax"></app-price-format>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Add-on -->
                                        <div class="flex justify-between items-center gap-2">
                                            <div class="flex items-center !gap-2">
                                                <p class="text-sm font-medium text-palette-gray-700">
                                                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.ADD_ON' | translate }}
                                                </p>
                                                <button *ngIf="serviceData[index].numSeat + serviceData[index].numMeal + serviceData[index].numExtraBaggage"
                                                    (click)="toggleSection('addon', index)" 
                                                    class="!w-5 !h-5 flex justify-center items-center rounded hover:bg-palette-gray-200">
                                                    <i class="{{sectionVisibility[index].addon ? 'sctr-icon-chevron-down' : 'sctr-icon-chevron-right'}} 
                                                        text-base leading-4 text-palette-gray-500"></i>
                                                </button>
                                            </div>
                                            <div class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="serviceData[index].totalSeat + 
                                                    serviceData[index].totalMeal + serviceData[index].totalExtraBaggage"></app-price-format>
                                            </div>
                                        </div>
                                        <div *ngIf="sectionVisibility[index].addon" class="flex flex-col gap-0.5 !pl-4">
                                            <div *ngIf="serviceData[index].numExtraBaggage" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500">
                                                    {{ 'COMMON.EXTRA_BAGGAGE' | translate }} x {{ serviceData[index].numExtraBaggage }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="serviceData[index].totalExtraBaggage"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="serviceData[index].numSeat" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500">
                                                    {{ 'FLIGHT.PAYMENT.SEAT_SELECTION' | translate }} x {{ serviceData[index].numSeat }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="serviceData[index].totalSeat"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="serviceData[index].numMeal" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500">
                                                    {{ 'FLIGHT.PAYMENT.MEAL' | translate }} x {{ serviceData[index].numMeal }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="serviceData[index].totalMeal"></app-price-format>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Adjustment -->
                                        <div *ngIf="priceDetails?.price_details[index]?.adjustment?.amount >=0" class="flex justify-between items-center gap-2">
                                            <p class="text-sm font-medium text-palette-gray-700">
                                                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.ADJUSTMENT' | translate }}
                                            </p>
                                            <p class="text-sm font-medium text-palette-gray-900">
                                                {{ priceDetails?.price_details[index]?.adjustment?.amount }}{{ priceDetails?.price_details[index]?.adjustment?.unit ?? '%' }}
                                            </p>
                                        </div>
                                        <!-- Soctrip’s tax -->
                                        <div *ngIf="priceDetails?.price_details[index]?.soctrip_tax" class="flex justify-between items-center gap-2">
                                            <p class="text-sm font-medium text-palette-gray-700">
                                                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.SOCTRIP_TAX' | translate }}
                                            </p>
                                            <p class="text-sm font-medium text-palette-gray-900">
                                                {{priceDetails?.price_details[index]?.soctrip_tax}}%
                                            </p>
                                        </div>
                                        <!-- Soctrip’s fee -->
                                        <div *ngIf="priceDetails?.price_details[index]?.soctrip_fee" class="flex justify-between items-center gap-2">
                                            <p class="text-sm font-medium text-palette-gray-700">
                                                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.SOCTRIP_FEE' | translate }}
                                            </p>
                                            <div class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="priceDetails?.price_details[index]?.soctrip_fee"></app-price-format>
                                            </div>
                                        </div>
                                        <!-- Membership fee -->
                                        <div class="flex justify-between items-center gap-2">
                                            <p class="text-sm font-medium text-palette-gray-700">
                                                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.MEMBERSHIP_FEE' | translate }}
                                            </p>
                                            <p *ngIf="priceDetails?.price_details[index]?.membership_percent" 
                                                class="text-sm font-medium text-palette-gray-900">
                                                {{priceDetails?.price_details[index]?.membership_percent}}
                                            </p>
                                            <div *ngIf="!priceDetails?.price_details[index]?.membership_percent" 
                                                class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="priceDetails?.price_details[index]?.membership_discount"></app-price-format>
                                            </div>
                                        </div>
                                    </div>
    
                                    <hr class="!my-3 border border-dashed">
    
                                    <!-- PRICE DETAILS -->
                                    <div class="flex items-center gap-2">
                                        <h4 class="text-palette-gray-900 text-sm font-semibold">{{ 'COMMON.PRICE_DETAILS' | translate }}</h4>
                                        <span class="flex justify-center items-center !w-4 !h-4 relative cursor-pointer">
                                            <i [tooltip]="('MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.PRICE_DETAILS_TOOLTIP' | translate)"
                                                [styleClass]="'text-palette-gray-700 text-sm font-normal min-w-[310px]'"
                                                class="sctr-icon-alert-circle text-base leading-4 text-palette-gray-600">
                                            </i>
                                        </span>
                                    </div>
                                    <!-- PRICE DETAILS LIST -->
                                    <div class="!mt-2 flex flex-col gap-1">
                                        <!-- Tickets -->
                                        <div class="flex justify-between items-center gap-2">
                                            <div class="flex items-center !gap-2">
                                                <p class="text-sm font-medium text-palette-gray-700">
                                                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.TICKETS' | translate }} 
                                                    ({{ (priceDetails?.price_details[index]?.num_of_adults ?? 0) + 
                                                        (priceDetails?.price_details[index]?.num_of_children ?? 0) + 
                                                        (priceDetails?.price_details[index]?.num_of_infants ?? 0) }})
                                                </p>
                                                <button (click)="toggleSection('priceDetails', index)" class="!w-5 !h-5 flex justify-center items-center rounded hover:bg-palette-gray-200">
                                                    <i class="{{sectionVisibility[index].priceDetails ? 'sctr-icon-chevron-down' : 'sctr-icon-chevron-right'}} 
                                                        text-base leading-4 text-palette-gray-500"></i>
                                                </button>
                                            </div>
                                            <div class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="priceDetails?.price_details[index]?.adult_fare_price +
                                                priceDetails?.price_details[index]?.child_fare_price + 
                                                priceDetails?.price_details[index]?.infant_fare_price"></app-price-format>
                                            </div>
                                        </div>
                                        <div *ngIf="sectionVisibility[index].priceDetails" class="flex flex-col gap-0.5 !pl-4">
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_adults" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{'COMMON.ADULT' | translate:{ syntax: 2 > 1 ? 's': '' } }} x 2
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.adult_fare_price"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_children" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{ (2>1 ?'COMMON.CHILDREN':'COMMON.CHILD') |translate }} x 1
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.child_fare_price"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="priceDetails?.price_details[index]?.num_of_infants" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500 first-letter:uppercase">
                                                    {{'COMMON.INFANT' | translate }} x1
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="priceDetails?.price_details[index]?.infant_fare_price"></app-price-format>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Add-on -->
                                        <div class="flex justify-between items-center gap-2">
                                            <div class="flex items-center !gap-2">
                                                <p class="text-sm font-medium text-palette-gray-700">
                                                    {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.ADD_ON' | translate }}
                                                </p>
                                                <button *ngIf="serviceData[index].numSeat + serviceData[index].numMeal + serviceData[index].numExtraBaggage"
                                                    (click)="toggleSection('priceAddon', index)" 
                                                    class="!w-5 !h-5 flex justify-center items-center rounded hover:bg-palette-gray-200">
                                                    <i class="{{sectionVisibility[index].priceAddon ? 'sctr-icon-chevron-down' : 'sctr-icon-chevron-right'}} 
                                                        text-base leading-4 text-palette-gray-500"></i>
                                                </button>
                                            </div>
                                            <div class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="serviceData[index].totalSeat + 
                                                    serviceData[index].totalMeal + serviceData[index].totalExtraBaggage"></app-price-format>
                                            </div>
                                        </div>
                                        <div *ngIf="sectionVisibility[index].priceAddon" class="flex flex-col gap-0.5 !pl-4">
                                            <div *ngIf="serviceData[index].numExtraBaggage" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500">
                                                    {{ 'COMMON.EXTRA_BAGGAGE' | translate }} x {{ serviceData[index].numExtraBaggage }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="serviceData[index].totalExtraBaggage"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="serviceData[index].numSeat" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500">
                                                    {{ 'FLIGHT.PAYMENT.SEAT_SELECTION' | translate }} x {{ serviceData[index].numSeat }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="serviceData[index].totalSeat"></app-price-format>
                                                </div>
                                            </div>
                                            <div *ngIf="serviceData[index].numMeal" class="flex justify-between items-center gap-2">
                                                <p class="text-sm font-medium text-palette-gray-500">
                                                    {{ 'FLIGHT.PAYMENT.MEAL' | translate }} x {{ serviceData[index].numMeal }}
                                                </p>
                                                <div class="text-sm text-palette-gray-500">
                                                    <app-price-format [price]="serviceData[index].totalMeal"></app-price-format>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Taxes and fees -->
                                        <div class="flex justify-between items-center gap-2">
                                            <p class="text-sm font-medium text-palette-gray-700">
                                                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.TAXES_AND_FEES' | translate }}
                                            </p>
                                            <div class="text-sm font-medium text-palette-gray-900">
                                                <app-price-format [price]="priceDetails?.price_details[index]?.taxes_charges"></app-price-format>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- VOUCHER & TOTAL -->
                <div class="flex justify-end">
                    <!-- Voucher -->
                    <div *ngIf="flightDetail?.price_details?.vouchers?.length" class="!pr-10">
                        <p class="text-sm font-semibold text-palette-gray-900 capitalize !mb-2">
                            {{'FLIGHT.PAYMENT.VOUCHER' | translate}}
                        </p>
                        <div class="flex flex-col !gap-1">
                            <div *ngFor="let item of flightDetail?.price_details?.vouchers">
                                <div class="flex justify-between items-center">
                                    <div class="flex items-center gap-1 bg-palette-emerald-50 !pr-2 !pl-[6px] !py-[2px] rounded-2xl">
                                        <img class="w-[12px] h-[12px]" src="assets/images/flight-view-details/checked-voucher.svg" alt="">
                                        <span class="text-system-success-700 font-inter text-xs leading-[18px] font-medium uppercase">
                                            {{item.voucher_code}}
                                        </span>
                                    </div>
                                    <div class="flex items-center">
                                        <span class="mr-[4px] text-xs !leading-[18px] font-semibold text-palette-gray-900"> - </span>
                                        <div class="font-inter text-sm font-semibold text-palette-gray-900">
                                            <app-price-format [price]="item.discount_price"></app-price-format>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="!my-4">
                    </div>
                    <!-- Total -->
                    <div class="flex justify-between items-center !gap-4">
                        <p class="text-palette-gray-900 text-base font-semibold leading-6">{{"COMMON.TOTAL" | translate}} </p>
                        <span class="text-palette-orange-dark-600 text-lg font-semibold">
                            <app-price-format [price]="totalPrice"></app-price-format>
                        </span>
                    </div>
                </div>
                <hr>
                <!-- FARE RULE -->
                <div>
                    <h3 class="text-palette-gray-900 text-base font-semibold">
                        {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.FARE_RULE' | translate }}
                    </h3>
                    <div *ngIf="!flightDetail?.flight_info?.one_way" class="rounded-lg !p-2 border border-palette-gray-300 shadow !mt-3">
                        <button *ngIf="!itinerary || itinerary?.isChosenDepart" (click)="toggleFareRule(0)" 
                            class="rounded !px-3 !py-2 text-sm font-semibold {{currentIndexFareRule === 0 ? 
                            'text-palette-blue-600 bg-palette-blue-50 hover:bg-palette-blue-100' : 'text-palette-gray-600 hover:bg-palette-gray-50'}}">
                            {{ 'COMMON.DEPART' | translate }}
                        </button>
                        <button *ngIf="itinerary?.isChosenReturn" (click)="toggleFareRule(1)" 
                            class="rounded !px-3 !py-2 text-sm font-semibold {{currentIndexFareRule === 1 ? 
                            'text-palette-blue-600 bg-palette-blue-50 hover:bg-palette-blue-100' : 'text-palette-gray-600 hover:bg-palette-gray-50'}}">
                            {{ 'COMMON.RETURN' | translate }}
                        </button>
                    </div>
                    <div class="!mt-4">
                        <fare-rule [fareRule]="currentFareRule" [fareRuleVi]="fareRuleVi" [resource]="resource"
                            [currentSegments]="currentSegments"></fare-rule>
                    </div>
                </div>
            </div>
        </div>
        <!-- REFUND -->
        <div [formGroup]="cancelForm" id="cancelForm" class="border border-palette-gray-200 !mt-3 !p-4 rounded-lg">
            <h3 class="text-palette-gray-900 text-base font-semibold">
                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.REFUND' | translate }}
            </h3>
            <div class="flex flex-col lg:flex-row justify-between gap-4 !mt-3">
                <div class="custom-radio w-full lg:!w-[33%]">
                    <p class="text-palette-gray-700 text-sm">
                        {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.REFUND_CONTENT' | translate }}
                    </p>
                    <!-- YES OR NO -->
                    <div class="flex items-center !gap-16 text-sm font-medium text-palette-gray-700 !mt-2">
                        <div class="flex items-center !gap-2">
                            <p-radioButton 
                                inputId="refundYes"
                                [value]="true"
                                formControlName="isRefund"
                                (ngModelChange)="onChangeYesOrNo(true)"
                                [ngClass]="{ 'is-invalid': f['isRefund']!.touched && f['isRefund']!.errors }"/>
                            <label for="refundYes">
                                {{ 'COMMON.YES' | translate }}
                            </label>
                        </div>
                        <div class="flex items-center !gap-2">
                            <p-radioButton 
                                inputId="refundNo" 
                                [value]="false"
                                formControlName="isRefund"
                                (ngModelChange)="onChangeYesOrNo(false)"
                                [ngClass]="{ 'is-invalid': f['isRefund']!.touched && f['isRefund']!.errors }"/>
                            <label for="refundNo">
                                {{ 'COMMON.NO' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <div *ngIf="f['isRefund'].value" class="w-full lg:!w-[66%] flex flex-col sm:flex-row !gap-4 sm:!gap-6">
                    <!-- REFUND AMOUNT -->
                    <div class="flex-1 flex flex-col !gap-2 search-custom">
                        <div class="flex items-center !gap-1">
                            <label for="refund_amount">{{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.REFUND_AMOUNT' | translate }}</label>
                            <span class="flex justify-center items-center !w-4 !h-4 relative cursor-pointer">
                                <i [tooltip]="('MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.REFUND_AMOUNT_TOOLTIP' | translate)"
                                    [styleClass]="'text-palette-gray-700 text-sm font-normal min-w-[310px]'"
                                    class="sctr-icon-alert-circle text-base leading-4 text-palette-gray-600">
                                </i>
                            </span>
                        </div>
                        <div class="relative">
                            <span class="absolute left-4 top-3 z-[999] text-sm text-palette-gray-700">$</span>
                            <input
                                pInputText
                                pKeyFilter="num"
                                id="refund_amount" 
                                placeholder="0.00"
                                formControlName="refundAmount"
                                class="text-sm font-medium text-palette-gray-700 custom-padding"
                                [ngClass]="{ 'is-invalid': f['refundAmount']!.touched && f['refundAmount']!.errors }">
                        </div>
                        <div *ngIf="f['refundAmount']?.touched && f['refundAmount']?.errors" class="invalid-feedback">
                            <div *ngIf="f['refundAmount']?.errors?.['required']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.REFUND_AMOUNT' | translate }} {{ 'COMMON.IS_REQUIRED' | translate }}
                            </div>
                        </div>
                    </div>
                    <!-- AGENCY BALANCE -->
                    <div class="flex-1 flex flex-col !gap-2 search-custom">
                        <div class="flex items-center !gap-1">
                            <label for="deduct_amount whitespace-nowrap">{{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.DEDUCT_AMOUNT' | translate }}</label>
                            <span class="flex justify-center items-center !w-4 !h-4 relative cursor-pointer">
                                <i [tooltip]="('MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.DEDUCT_AMOUNT_TOOLTIP' | translate)"
                                    [position]="TooltipPosition.LEFT"
                                    [styleClass]="'text-palette-gray-700 text-sm font-normal !min-w-[100px] !max-w-[240px]'"
                                    class="sctr-icon-alert-circle text-base leading-4 text-palette-gray-600">
                                </i>
                            </span>
                        </div>
                        <div class="relative">
                            <span class="absolute left-4 top-3 z-[999] text-sm text-palette-gray-700">$</span>
                            <input
                                pInputText
                                pKeyFilter="num"
                                id="deduct_amount" 
                                placeholder="0.00"
                                formControlName="deductAmount"
                                class="text-sm font-medium text-palette-gray-700 custom-padding"
                                [ngClass]="{ 'is-invalid': f['deductAmount']!.touched && f['deductAmount']!.errors }">
                        </div>
                        <div *ngIf="f['deductAmount']?.touched && f['deductAmount']?.errors" class="invalid-feedback">
                            <div *ngIf="f['deductAmount']?.errors?.['required']" class="flex items-center gap-1">
                                <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
                                {{ 'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.DEDUCT_AMOUNT' | translate }} {{ 'COMMON.IS_REQUIRED' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    </div>
    <!-- FOOTER -->
    <div class="flex justify-end items-center !gap-3 shadow !p-6 border-t border-palette-gray-200">
        <button (click)="onClickCancel()"
            class="rounded-lg border border-palette-gray-300 bg-white hover:!bg-palette-gray-200 text-palette-gray-900 text-sm font-semibold !px-5 !py-3 transition-all">
            {{ 'COMMON.CANCEL' | translate }}
        </button>
        <button (click)="onClickConfirm()"
            [disabled]="isCallAPI"
            class="rounded-lg text-white text-sm font-semibold !px-5 !py-3 transition-all !h-11 min-w-[104px] flex justify-center items-center"
            [ngClass]="isCallAPI ? 'bg-palette-blue-400': 'bg-palette-blue-600 hover:!bg-palette-blue-500'">
            <ng-container *ngIf="!isCallAPI">
                {{ 'COMMON.CONFIRM' | translate }}
            </ng-container>
            <ng-container *ngIf="isCallAPI">
                <app-loading [style]="'!w-8 !h-8'"></app-loading>
            </ng-container>
        </button>
    </div>
</div>

<!-- POPUP DISCARD -->
<p-dialog [header]="'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.DISCARD_CHANGES' | translate" [draggable]="false" [modal]="true" [baseZIndex]="10002"
    class="booking-issue-status-note-dialog discard-changes-dialog cursor-default text-lg !mx-2" [dismissableMask]="true"
    [style]="{ width: '600px', height: '220px' }" [(visible)]="isShowPopupDiscard" (onHide)="onKeepEditing()">
    <div class="flex flex-col bg-white ">
        <span class="text-sm">
            {{ "MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.DISCARD_CHANGES_DESCRIPTION" | translate }}
        </span>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-end items-center text-sm">
            <button
                class="!h-11 !min-w-[88px] px-2 border border-palette-gray-300 text-palette-gray-900 font-semibold rounded-lg hover:bg-palette-gray-200 focus:outline-none focus:ring-2 focus:ring-palette-gray-300 focus:ring-opacity-50"
                (click)="onDiscard()">
                {{ "MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.DISCARD" | translate }}
            </button>
            <button
                class="!h-11 !min-w-[120px] px-2 bg-palette-blue-600 text-white font-semibold rounded-lg hover:bg-palette-blue-500 focus:outline-none focus:ring-2 focus:ring-[#135fc9] focus:ring-opacity-50"
                (click)="onKeepEditing()">
                {{ "MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.KEEP_EDITING" | translate }}
            </button>
        </div>
    </ng-template>
</p-dialog>

<!-- POPUP CONFIRM -->
<p-dialog [header]="'MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.UPDATE_ISSUE_CONFIRMATION' | translate" [draggable]="false" [modal]="true" [baseZIndex]="10002"
    class="booking-issue-status-note-dialog update-changes-dialog cursor-default text-lg !mx-2" [dismissableMask]="true"
    [style]="{ width: '600px', height: '220px' }" [(visible)]="isShowPopupConfirm"
    (onHide)="onKeepEditing()">
    <div class="flex flex-col bg-white ">
        <span *ngIf="f['isRefund'].value" class="text-sm">
            <ng-container *ngIf="type === 'REQUEST_REFUND'">
                {{ "MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.RR_CONFIRM_YES_CONTENT" | translate }}
            </ng-container>
            <ng-container *ngIf="type !== 'REQUEST_REFUND'">
                {{ "MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.CONFIRM_YES_CONTENT" | translate }}
            </ng-container>
        </span>
        
        <span *ngIf="!f['isRefund'].value" class="text-sm">
            <ng-container *ngIf="type === 'REQUEST_REFUND'">
                {{ "MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.RR_CONFIRM_NO_CONTENT" | translate }}
            </ng-container>
            <ng-container *ngIf="type !== 'REQUEST_REFUND'">
                {{ "MY_FLIGHT_BOOKING_DETAIL.BOOKING_ISSUE_DETAILS.CONFIRM_NO_CONTENT" | translate }}
            </ng-container>
        </span>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-end items-center text-sm">
            <button
                class="!h-11 !min-w-[88px] px-2 border border-palette-gray-300 text-palette-gray-900 font-semibold rounded-lg hover:bg-palette-gray-200 focus:outline-none focus:ring-2 focus:ring-palette-gray-300 focus:ring-opacity-50"
                (click)="onKeepEditing()">
                {{ "COMMON.CANCEL" | translate }}
            </button>
            <button
                class="!h-11 !min-w-[120px] px-2 bg-palette-blue-600 text-white font-semibold rounded-lg hover:bg-palette-blue-500 focus:outline-none focus:ring-2 focus:ring-[#135fc9] focus:ring-opacity-50"
                (click)="onForceConfirm()">
                {{ "COMMON.CONFIRM" | translate }}
            </button>
        </div>
    </ng-template>
</p-dialog>