import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { environment } from '@env/environment';
import { TokenStorageService } from '@modules/users/services/token-storage.service';
import { Observable } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  reset() {
    localStorage.removeItem(environment.USER_PROFILE);
    localStorage.removeItem(environment.TOKEN_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
    localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
    localStorage.removeItem(environment.USER_ROLE_KEY);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user = JSON.parse(
      localStorage.getItem(environment.USER_PROFILE) || '{}'
    );
    try {
      if (user?.id) {
        this.userService.getUserInfoById(user.id).subscribe((data) => {
          if (data) {
            this.userService.setUserInfo(data);
            this.userService.setUserProfile(data);
            localStorage.setItem(
              environment.USER_PROFILE,
              JSON.stringify(data)
            );
            this.userService.setLoginStatus(true);
            localStorage.setItem(
              'auth_status',
              JSON.stringify({ isLoggedIn: true })
            );
          } else {
            this.reset();
            this.userService.setLoginStatus(false);
            localStorage.setItem(
              'auth_status',
              JSON.stringify({ isLoggedIn: false })
            );
            // this.router.navigate(['login']);
          }
        });

        this.userService.getModuleRole().subscribe({
          next: (res: any) => {
            if(res.success) {
              localStorage.setItem(
                'roles_module',
                JSON.stringify(res.data)
              );
            }
          }
        })
      } else {
        // this.router.navigate(['login']);
      }
    } catch (err) {
      console.error(err);
    }
    return true;
  }
}
