import { HttpClient, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { AUTH_INTERCEPTOR_PROVIDER } from './core/interceptors/auth/auth.interceptor';
import {
  ApiModule as PropertyDataServiceApiModule,
  BASE_PATH as PROPERTY_DATA_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-property-data-service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  ApiModule as StorageServiceApiModule,
  BASE_PATH as STORAGE_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-storage-service';
import {
  ApiModule as PropertySearchServiceApiModule,
  BASE_PATH as PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-property-search-service';
import {
  ApiModule as BookingGenericServiceApiModule,
  BASE_PATH as BOOKING_GENERIC_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-generic-service';
import { environment } from '@env/environment';
import {
  ApiModule as ShipmentServiceApiModule,
  BASE_PATH as SHIPMENT_SERVICE_API_BASE_PATH,
} from '@soctrip/angular-shipment-service';

import {
  ApiModule as BookingAdminApiModule,
  BASE_PATH as BOOKING_ADMIN_SERVICE_API_BASE_PATH,
} from '@booking/angular-booking-admin-service';
import {
  ApiModule as BookingUserDataApiModule,
  BASE_PATH as BOOKING_USER_DATA_API_BASE_PATH,
} from '@booking/angular-booking-user-data-service';

import {
  ApiModule as EcommerceWalletApiModule,
  BASE_PATH as ECOMMERCE_WALLET_API_BASE_PATH,
} from '@soctrip/angular-ecommerce-wallet-service';
import { MessageService } from 'primeng/api';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import {
  ApiModule as AirportInfoControllerService,
  BASE_PATH as BOOKING_FLIGHT_BASE_PATH,
} from '@booking/angular-booking-flight-data-service';
import {
  ApiModule as RegionalApiModule,
  BASE_PATH as REGIONAL_API_BASE_PATH,
} from '@soctrip/angular-regional-service';
import {
  ApiModule as BookingFlightOrdersApiModule,
  BASE_PATH as BOOKING_FLIGHT_ORDERS_API_BASE_PATH,
} from '@booking/angular-booking-flight-orders-service';

registerLocaleData(localeVi, 'vi');

export const APP_CONFIG: ApplicationConfig = {
  providers: [
    MessageService,
    importProvidersFrom([
      /* @angular */
      HttpClientModule,
      ReactiveFormsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => {
            return new TranslateHttpLoader(http, './assets/i18n/', '.json');
          },
          deps: [HttpClient],
        },
      }),
      PropertySearchServiceApiModule,
      BookingGenericServiceApiModule,
      ShipmentServiceApiModule,
      StorageServiceApiModule,
      PropertyDataServiceApiModule,
      BookingAdminApiModule,
      BookingUserDataApiModule,
      EcommerceWalletApiModule,
      AirportInfoControllerService,
      RegionalApiModule,
      BookingFlightOrdersApiModule
    ]),
    provideAnimations(),
    provideRouter(APP_ROUTES),
    AUTH_INTERCEPTOR_PROVIDER,
    {
      provide: PROPERTY_DATA_SERVICE_API_BASE_PATH,
      useValue: environment.PROPERTY_DATA_SERVICE_API_BASE_PATH,
    },
    {
      provide: PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
      useValue: environment.PROPERTY_SEARCH_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKING_GENERIC_SERVICE_API_BASE_PATH,
      useValue: environment.BOOKING_GENERIC_SERVICE_API_BASE_PATH,
    },
    {
      provide: SHIPMENT_SERVICE_API_BASE_PATH,
      useValue: environment.SHIPMENT_SERVICE_API_BASE_PATH,
    },
    {
      provide: STORAGE_SERVICE_API_BASE_PATH,
      useValue: environment.STORAGE_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKING_ADMIN_SERVICE_API_BASE_PATH,
      useValue: environment.BOOKING_ADMIN_SERVICE_API_BASE_PATH,
    },
    {
      provide: BOOKING_USER_DATA_API_BASE_PATH,
      useValue: environment.BOOKING_USER_DATA_API_BASE_PATH,
    },
    {
      provide: ECOMMERCE_WALLET_API_BASE_PATH,
      useValue: environment.ECOMMERCE_WALLET_API_BASE_PATH,
    },
    {
      provide: BOOKING_FLIGHT_BASE_PATH,
      useValue: environment.BOOKING_FLIGHT_BASE_PATH,
    },
    {
      provide: REGIONAL_API_BASE_PATH,
      useValue: environment.REGIONAL_API_BASE_PATH,
    },
    {
      provide: BOOKING_FLIGHT_ORDERS_API_BASE_PATH,
      useValue: environment.BOOKING_FLIGHT_ORDERS_API_BASE_PATH,
    },
  ],
};
