import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { environment } from '@env/environment';
import { ViewDetailsComponent } from '@modules/flight-admin/components/booking-helpdesk/reservation-detail/view-details/view-details.component';
import { PreviewETicketComponent } from '@shared/components/preview-e-ticket/preview-e-ticket.component';
import { PermissionGuard } from './core/guards/permission/permission.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'admin',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      roles: ['USER'],
    },
    children: [
      {
        path: '',
        // component: CategoryModuleComponent,
        redirectTo: 'flight-admin',
        pathMatch: 'full',
      },
      {
        path: 'flight-admin',
        loadChildren: () =>
          import('@modules/flight-admin/flight-admin.module').then(
            (module) => module.FlightAdminModule
          ),
      },
      {
        path: 'flight/booking-helpdesk/:bookingId',
        component: ViewDetailsComponent,
      },
      {
        path: 'booking-issue/preview-e-ticket/:reservationCode/:type',
        component: PreviewETicketComponent,
      },
    ],
  },
  {
    path: 'common/icon',
    loadComponent: () =>
      import('./modules/common/icon/icon.component').then(
        ({ IconComponent }) => IconComponent
      ),
    title: 'Booking: Icon',
  },
  {
    path: 'lg12345',
    loadComponent: () =>
      import('./modules/users/components/sign-in/sign-in.component').then(
        ({ SignInComponent }) => SignInComponent
      ),
    title: environment.PRODUCT_NAME + ': Sign In',
  },
  {
    path: 'common/version',
    loadComponent: () =>
      import('./modules/common/version/version.component').then(
        ({ VersionComponent }) => VersionComponent
      ),
    title: 'checking version',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./layout/page-not-found/page-not-found.component').then(
        ({ PageNotFoundComponent }) => PageNotFoundComponent
      ),
    title: 'Page Not Found',
  },
];
