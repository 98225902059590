import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlightBookingIssueControllerService } from '@booking/angular-booking-admin-service';
import { TranslateService } from '@ngx-translate/core';
import { TIME_OUT_REQUEST } from '@shared/constant';
import moment from 'moment';
import { catchError, finalize, of, Subject, takeUntil, timeout } from 'rxjs';
import { ToastMessageService } from 'src/app/services/toast-message/toast-message.service';

@Component({
    selector: 'preview-e-ticket',
    templateUrl: './preview-e-ticket.component.html',
    styleUrls: ['./preview-e-ticket.component.scss'],
})
export class PreviewETicketComponent {
    tooltipElement: HTMLElement | null = null;
    tooltipWidth: number = 0;
    tooltipHeight: number = 0;
    reservationCode: string;
    segmentType: string;
    isLoading: boolean = true;
    previewData: any;
    segments: any[] = [];
    dateIssue: Date;
    timeIssue: string;
    currentYear: number;
    passengers: any[] = [];
    onDestroy$: Subject<void> = new Subject();
    flight: any;
    contactInfo: any;
    key: string;
    durationFlight: any;

    constructor(
        private toastMessageService: ToastMessageService,
        private flightBookingIssueControllerService: FlightBookingIssueControllerService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
    ) { }

    ngOnInit() {
        this.reservationCode = this.activatedRoute.snapshot.paramMap.get('reservationCode') ?? '';
        this.segmentType = this.activatedRoute.snapshot.paramMap.get('type') ?? '';

        if (this.segmentType !== 'depart' && this.segmentType !== 'return') {
            this.router.navigate(['/home']);
        }

        this.currentYear = new Date().getFullYear();
        this.getPreviewETicketData();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    onMouseEnter(event: MouseEvent, passenger: any) {
        const x = event.pageX;
        const y = event.pageY;
        const html = `
            <div class="!px-4 !py-3 bg-white rounded-lg text-black font-semibold text-xs shadow-lg opacity-1 max-w-xs">
                <div>
                    <p class="font-inter text-xs !font-semibold text-palette-gray-500">Membership</p>
                    <div class="font-inter text-sm !font-normal !mt-2 !flex items-center !gap-1 text-palette-gray-900">
                        <div class="flex items-center !gap-1 font-inter whitespace-nowrap !font-normal text-sm text-palette-gray-900">
                            ${
                                passenger?.flyer_info?.flyer_program 
                                ? `<span>${passenger?.flyer_info?.flyer_program}</span>`
                                : `<div class="w-[14px] h-[2px] border border-palette-gray-300"></div>`
                            }
                            <span> - </span>
                            ${
                                passenger?.flyer_info?.flyer_number
                                ? `<span class="uppercase">${passenger?.flyer_info?.flyer_number}</span>`
                                : `<div class="w-[14px] h-[2px] border border-palette-gray-300"></div>`
                            }
                        </div>
                    </div>
                </div>
            </div>
        `

        if (!this.tooltipElement) {
            this.tooltipElement = document.createElement('div');
            this.tooltipElement.innerHTML = html;
            this.tooltipElement.style.position = 'absolute';
            this.tooltipElement.style.zIndex = '9999';
            document.body.appendChild(this.tooltipElement);
            this.tooltipWidth = this.tooltipElement.offsetWidth;
            this.tooltipHeight = this.tooltipElement.offsetHeight;
        }

        this.tooltipElement.style.left = x - 73 + 'px';
        this.tooltipElement.style.top = y - 85 + 'px';
    }

    onMouseLeave() {
        if (this.tooltipElement && this.tooltipElement.parentNode) {
            this.tooltipElement.parentNode.removeChild(this.tooltipElement);
            this.tooltipElement = null;
        }
    }

    formatBaggages(baggage: any): string | null {    
        if(!baggage) return null;
        if (
          baggage?.number === null
          && baggage?.weight === null
          && baggage?.unit === null
          && baggage?.description === null
        ) {
          return null;
        }
        if (baggage?.description === 'not included') {
          return this.translateService.instant('MY_FLIGHT_BOOKING_DETAIL.BAGGAGE_ALLOWANCE.NOT_INCLUDED')
        }
        if (baggage?.description === 'included') {
          return this.translateService.instant('FLIGHT.VIEW_DETAILS_SIDEBAR.INCLUDED');;
        }
        if (baggage?.number !== null) {
          if (baggage?.weight === null && baggage?.unit === null) {
            return `${baggage?.number} ${this.translateService.instant('COMMON.PCS')}`;
          }
          if (baggage?.weight !== null && baggage?.unit !== null) {
            return `${baggage?.number}x${baggage?.weight} ${baggage?.unit}`;
          }
          if (baggage?.weight !== null) {
            return `${baggage?.number}x${baggage?.weight}`;
          }
          if (baggage?.unit !== null) {
            return `${baggage?.number}x${baggage?.unit}`;
          }
        }
        if (baggage?.weight !== null && baggage?.unit !== null) {
          return `${baggage?.weight} ${baggage?.unit}`;
        }
        return null;
    }

    getPreviewETicketData() {
        this.flightBookingIssueControllerService.getInfo(this.reservationCode)
            .pipe(
                timeout(TIME_OUT_REQUEST),
                takeUntil(this.onDestroy$),
                catchError((error) => {
                    if (error.name === 'TimeoutError') {
                        this.toastMessageService.showError('TIME_OUT_REQUEST');
                    } else if (error.status === 0) {
                        this.toastMessageService.showError('ERR_INTERNET_DISCONNECTED');
                    } else {
                        this.toastMessageService.showError(error.error.error);
                    }
                    return of(null);
                }),
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe((res: any) => {
                if (res?.success) {
                    this.previewData = res.data;
                    this.contactInfo = this.previewData.contact_info;
                    this.passengers = this.previewData.passenger_info;
                    if(this.previewData.segment_type === 'departure') {
                        this.key = 'departure';
                        this.flight = this.previewData.departure_flight;
                    } else {
                        this.key = 'return';
                        this.flight = this.previewData.return_flight;
                    }
                    this.durationFlight = this.calculateTotalDuration();
                    // const requestedAt = this.previewData?.requested_at;
                    // if (requestedAt) {
                    //     this.dateIssue = this.convertToUserTimeZone(requestedAt);
                    //     this.timeIssue = moment(requestedAt).format('HH:mm');
                    // }
                    // this.segments = this.previewData?.segments || [];
                }
            });
    }

    calculateTotalDuration(): string {
        const totalTime = this.flight.reduce((acc: any, flight: any) => {
          const time = this.parseDuration(flight.duration);
      
          let totalMinutes = acc.minutes + time.minutes;
          let totalHours = acc.hours + time.hours;
      
          totalHours += Math.floor(totalMinutes / 60);
          totalMinutes = totalMinutes % 60;
      
          return {
            hours: totalHours,
            minutes: totalMinutes,
          };
        }, { hours: 0, minutes: 0 });
      
        return `${totalTime.hours}h${totalTime.minutes}m`;
    }
      
    parseDuration(duration: string): { hours: number; minutes: number } {
        const match = duration.match(/(\d+)h(\d+)m/);
        if (!match) {
            return {
                hours: 0,
                minutes: 0
            }
        }
        return {
            hours: parseInt(match[1], 10),
            minutes: parseInt(match[2], 10),
        };
    }

    convertToUserTimeZone(utcTime: string): any {
        return moment.utc(utcTime).toDate();
    }

    getUTCOffset() {
        const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        const offsetHours = Math.abs(timezoneOffsetInMinutes / 60);
        const offsetSign = timezoneOffsetInMinutes > 0 ? '-' : '+';
        return `(UTC${offsetSign}${offsetHours})`;
    }

    getTime(dateTime: any) {
        if (!dateTime) {
            return '';
        }
        return moment(dateTime).format('HH:mm');
    }

    getDate(dateTime: any) {
        if (!dateTime) {
            return '';
        }
        return moment(dateTime).format('YYYY-MM-DD');
    }

    formatDuration(timeString: string, space: boolean): string {
        if (!timeString) {
            return '';
        }
        
        const timeParts = timeString.split(':');
        const hours = Number(timeParts[0]);
        const minutes = Number(timeParts[1]);

        let formattedDuration = '';

        if (hours > 0) {
            if (space) {
                formattedDuration += `${hours}h `;
            } else {
                formattedDuration += `${hours}h`;
            }
        }

        formattedDuration += `${minutes}m`;

        return formattedDuration;
    }
}